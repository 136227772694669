export interface TwirpErrorJSON {
    code: string;
    msg: string;
    meta: {[index:string]: string};
}

export class TwirpError extends Error {
    code: string;
    meta: {[index:string]: string};

    constructor(te: TwirpErrorJSON) {
        super(te.msg);

        this.code = te.code;
        this.meta = te.meta;
    }
}

export const TOKEN_HEADER = 'Session';

export const throwTwirpError = (err: TwirpErrorJSON) => {
  throw new TwirpError(err);
};

export const createTwirpRequest = (url: string, body: object | void = {}, token: string): Request => {
  const headers: HeadersInit = {
    "Content-Type": "application/json"
  };

  if (token) {
    headers[TOKEN_HEADER] = token;
  }

  const options: RequestInit = {
    method: "POST",
    headers,
  }

  if (body) {
    options.body = JSON.stringify(body);
  }

  return new Request(url, options);
};

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
