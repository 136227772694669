export const formatDigits = (n: number, separator: string = ' '): string =>
  n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator);

export const delay = (timeout = 1000) => new Promise(r => window.setTimeout(r, timeout));

export const randomInt = (maxValue: number): number => Math.floor(Math.random() * maxValue);

export const copyText = (text: string) => {
  const element = document.createElement('textarea');
  element.value = text;
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}

interface ParseControlCharsOptions {
  newLinePadding?: boolean
}

export const parseControlChars = (input: string, options: ParseControlCharsOptions = {}): string =>
  input
    .replaceAll('\n', options.newLinePadding ? '<br /><br />' : '<br />')
    .replaceAll('\t', '&nbsp;')


export function median(values: Array<number>){
  if(values.length ===0) return 0;

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export const stopBrowserBack = (cb?: () => void ) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    cb?.()
  };
};

export const startBrowserBack = () => {
  window.onpopstate = null;
  // window.history.back();
};
