import { delay } from './common';

interface SlipOptions {
  attempts: number;
  errorAttempts: number;
  errorDelay: number;
  timeout: number;
  onError: (err: any, attempt: number, errorAttempt: number) => void;
  onAttempt: (attempt: number) => void;
}

const DEFAULT_OPTIONS: SlipOptions = {
  attempts: 5,
  errorAttempts: 3,
  errorDelay: 200,
  timeout: 3000,
  onError: () => undefined,
  onAttempt: () => undefined,
};

function methodSlip <F extends (...args: any ) => Promise<any>> (method: F, args: Parameters<F>, slipOptions: Partial<SlipOptions> = {}): Promise<ReturnType<F>> {
  const options = {
    ...DEFAULT_OPTIONS,
    ...slipOptions,
  };

  let isFinished = false;
  let attempt = 0;
  let errorAttempt = 0;

  const methodCall: (index: number) => Promise<ReturnType<F>> = (index) => method(...args)
    .then((r) => {
      isFinished = true;
      return r;
    })
    .catch(async (err: any) => {
      options.onError(err, index, errorAttempt);
      if (errorAttempt >= options.errorAttempts - 1) {
        isFinished = true;
        return Promise.reject(err);
      }

      await delay(options.errorDelay);

      if (attempt === index) {
        errorAttempt ++ ;

        return methodCall(index);
      } else {
        // dead branch
        await delay(options.timeout * options.attempts + 1000);
        return Promise.reject(err);
      }
    });

  return Promise.race(
    Array.apply(null, ({ length: options.attempts } as any)).map(async (_, index) => {
      await delay(index * options.timeout);
      attempt = index;
      errorAttempt = 0;

      if (isFinished) return 0 as any;

      options.onAttempt(index);
      return methodCall(index);
    })
  );
}

export { methodSlip };
