import React, { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import intervalToDuration from 'date-fns/esm/intervalToDuration';

import type { Quiz } from 'types';
import { logAmplitude } from 'utils/amplitude';
import { Loader, Manual } from 'components';

import './Lobby.less';

interface IProps {
  quiz: Quiz;
}

const getDistanceMessage = (t: TFunction, time: Date): string | null => {
  const now = new Date();

  const diff = Number(time) - Number(now);
  if (diff < 0) {
    return null
  }

  const duration = intervalToDuration({ start: now, end: time });

  return diff > 5 * 60 * 1000 // 5 min
    ? t('Lobby.timer', duration)
    : t('Lobby.timerSec', duration);
}

const Lobby: React.FC<IProps> = ({ quiz }) => {
  const { t } = useTranslation();
  const [distance, setDistance] = useState(getDistanceMessage(t, quiz.startTime));

  useEffect(() => {
    setDistance( getDistanceMessage(t, quiz.startTime));
    const updateInterval = window.setInterval(() => setDistance( getDistanceMessage(t, quiz.startTime) ), 1_000)
    return () => {
      window.clearInterval(updateInterval);
    }
  }, [quiz.startTime]);

  useEffect(() => {
    logAmplitude('tvquiz_app_lobby');
  }, []);

  return (
    <div className="quiz-container">
      <div className="quiz-card quiz-lobby-card">
        <div className="quiz-font-600 quiz-lobby-title ">{t('Lobby.title')}</div>
        <div className="quiz-font-500 quiz-text-caption" style={{ marginTop: 24 }}>{t('Lobby.quizStartIn')}</div>
        <div className="quiz-font-800 quiz-lobby-timer">{distance ?? <Loader className="quiz-lobby-loader" />}</div>
      </div>
      <Manual />
    </div>
  );
}

export default React.memo(Lobby);

