import api from 'api';
import type { Question } from 'types';
import { delay, randomInt } from 'utils/common';

import { EQuizError } from './quizService';
import QuizServiceHttp from './quizServiceHttp';

const POLLING_INTERVAL = 3_500;

export default class QuizServiceHttpPolling extends QuizServiceHttp {
  disconnect(reconnect: boolean = false) {
    super.disconnect(reconnect);

    window.clearInterval(this.getNextQuestionPollingInterval);
    this.getNextQuestionPollingInterval = 0;
  }

  private getNextQuestionPollingInterval = 0;

  protected async getNextQuestion(then: Date) {
    if (this.getNextQuestionPollingInterval) {
      return;
    }

    const method = 'GetNextQuestion';
    
    let lateCount = 0

    this.getNextQuestionPollingInterval = window.setInterval(async () => {
      await delay(randomInt(1000))  // jitter

      try {
        const result = await api.onlineQuiz.getNextQuestion({ quizId: this.quiz.id });

        if ('error' in result) {
          switch (result.error) {
            case api.onlineQuiz.GetNextQuestionError.LATE:
              if (lateCount < 5) {
                lateCount++
              } else {
                this.handleError({ type: EQuizError.logic, method, message: api.onlineQuiz.GetNextQuestionError.LATE });
                this.disconnect();
              }
              break;
            case api.onlineQuiz.GetNextQuestionError.LOSE:
              this.handleError({ type: EQuizError.logic, method, message: api.onlineQuiz.GetNextQuestionError.LOSE });
              this.disconnect();
              break;
          }
        } else {
          lateCount = 0
          this.handleLifeCount(result.lifeCount);
          this.handleQuestion(result.question);
        }
      } catch (error) {
        lateCount = 0
        this.handleError({ type: EQuizError.api, method, error })
      }
    }, POLLING_INTERVAL);
  }

  protected async handleQuestion(question: Question) {
    if (this.question?.id === question.id) return;
    this.question = question;

    this.emit('question', question);
  }
}

