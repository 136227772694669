export interface Quiz {
  id: string;
  startTime: Date;
  name: string;
  questionCount: number;
  title: string;
  description: string;
  finished: boolean;
  offer: string;
  isUserAcceptOffer: boolean;
}

export interface Lobby {
  peopleCount: number;
}

export interface Answer {
  id: string;
  text: string;
}

export interface Question {
  id: string;
  quizId: string;
  questionText: string;
  variants: Array<Answer>;
  startTime: Date;
  endTime: Date;
  index: number;
}

export enum AnswerState {
  LOSE = 'LOSE', // if no life more or incorrect answer
  BURNED_LIFE = 'BURNED_LIFE',
  PASSED = 'PASSED',
  WIN = 'WIN', // endTime of the quiz
}

export interface AnswerResult {
  questionId: string; // only on client!
  correct: boolean;
  correctAnswer: Answer;
  lifeCount: number;
  nextQuestionStartTime?: Date;
  state: AnswerState;
  answeredCount: number;
  passThreshold: boolean;
}

export interface CorrectAnswered {
  count: number;
}

export interface Winners {
  count: number;
}
