import * as common from './protocol/common'
import * as onlineQuiz from './protocol/onlineQuiz'
import * as offlineQuiz from './protocol/offlineQuiz'

import * as OPTIONS from './options'

export default {
  ...common,
  onlineQuiz,
  offlineQuiz,
  OPTIONS,
}
