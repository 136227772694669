import React, { useEffect, useRef, useState } from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';
import { observer } from 'mobx-react-lite';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { OfflineQuizStore, Store } from 'store';
import dialogs from 'dialogs';
import { Button } from 'components';
import { formatDigits, stopBrowserBack, startBrowserBack } from 'utils/common';
import { ReactComponent as IconCoins } from 'assets/coins.svg'
import { ReactComponent as IconInfo } from 'assets/info.svg';
import Happy from 'assets/happy.png';
import Unhappy from 'assets/unhappy.png';
import Sunglasses from 'assets/sunglasses.png'

import '../OnlineQuiz/OnlineQuiz.less' // Use same styles
import '../OfflineQuiz/OfflineQuiz.less'

import './OfflineQuizFinish.less';
import { logAmplitude } from 'utils/amplitude';

interface IProps {
  $f7router: Router.Router;
}

const getThings: (result: number, t: TFunction) => { emotion: string, comment: string, cardClass: string } = (result, t) => {
  let emotion
  let comment
  let cardClass

  if (result > 19) {
    emotion = Sunglasses
    comment = t('Finish.excellent')
    cardClass = 'quiz-finish-card__success'
  } else if (result > 15) {
    emotion = Happy
    comment = t('Finish.good')
    cardClass = ''
  } else {
    emotion = Unhappy
    comment = t('Finish.bad')
    cardClass = 'quiz-finish-card__error'
  }

  return {
    emotion,
    comment,
    cardClass,
  }
}


const OfflineQuizFinish: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const rateDialogHasBeenOpened = useRef(false)
  const rateDialogTimeout = useRef(0)

  const back = () => {
    window.clearTimeout(rateDialogTimeout.current)
    logAmplitude('tvquiz_app_quiz_exit_clicked' , { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })
    OfflineQuizStore.reset()
    $f7router.navigate('/play', { clearPreviousHistory: true })
  }

  const handleBackClick = () => {
    if (OfflineQuizStore.quiz?.userRateQuiz || rateDialogHasBeenOpened.current) {
      back()
      return
    }

    dialogs.rateQuizDialog.once('closed', back)
    dialogs.rateQuizDialog.open()
    rateDialogHasBeenOpened.current = true
  }

  useEffect(() => {
    if (OfflineQuizStore.quiz?.id && OfflineQuizStore.quizResult) {

      rateDialogHasBeenOpened.current = false

      const handleRouteChange = (newRoute: Router.Route, prevRoute: Router.Route) => {
        if (prevRoute.path === '/offline-quiz-finish' && newRoute.path !== '/offline-quiz' && !rateDialogHasBeenOpened.current) {
          dialogs.rateQuizDialog.open()
          rateDialogHasBeenOpened.current = true
        }
      }


      if (!OfflineQuizStore.quiz?.userRateQuiz) {

        $f7router.once('routeChange', handleRouteChange);

        rateDialogTimeout.current = window.setTimeout(() => {
          if (!dialogs.rateQuizDialog.opened && !rateDialogHasBeenOpened.current) {
            dialogs.rateQuizDialog.open()
            rateDialogHasBeenOpened.current = true
          }
        }, 3_000)
      }

      return () => {
        $f7router.off('routeChange', handleRouteChange)
        window.clearTimeout(rateDialogTimeout.current)
      }
    }

  }, [OfflineQuizStore.quiz?.id, OfflineQuizStore.quizResult])


  if (!OfflineQuizStore.quizResult || !OfflineQuizStore.quiz) {
    return null
  }

  const handlePlayAgainClick = () => {
    if (loading) {
      return
    }

    window.clearTimeout(rateDialogTimeout.current)
    logAmplitude(' tvquiz_app_answer_final_retry_clicked', { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })


    if (OfflineQuizStore.attempts < 1) {
      dialogs.promoCodeDialog.open()
      return
    }

    setLoading(true)

    OfflineQuizStore.restart(OfflineQuizStore.quiz!)
      .then(() => {
        $f7router.navigate('/offline-quiz' , { pushState: false, history: false })
      })
      .catch(() => {
        $f7router.navigate('/play')
      })
      .finally(() => {
        setLoading(false)
      })
  }


  const handleActionButtonClick = () => {
    if (!OfflineQuizStore.quiz?.actionUrl) return

    window.clearTimeout(rateDialogTimeout.current)
    logAmplitude('tvquiz_app_link', { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })
    window.location.href = OfflineQuizStore.quiz.actionUrl
  }

  const { emotion, cardClass, comment } = getThings(OfflineQuizStore.quizResult.answeredQuestions!, t)

  return (
    <Page>
      <div className="quiz-finish-container">

        <div className={'quiz-finish-card ' + cardClass}>

          <img className="quiz-finish-emotion" alt="emotion" src={emotion} />

          <div className="mt-24 quiz-text-secondary quiz-font-700">{comment}</div>

          <div className="mt-24 block-between">
            <span className="quiz-finish-card-text quiz-font-700">{t('Finish.youGet')}</span>
            <span className="quiz-finish-card-text quiz-font-700 text-capitalize">{t('common.points', { count: OfflineQuizStore.quizResult.totalScore })}</span>
          </div>

          <div className="mt-24 block-between">
            <span className="quiz-finish-card-text">{t('Finish.answers')}</span>
            <span className="quiz-finish-card-text quiz-font-700">{OfflineQuizStore.quizResult.answeredQuestions}/{OfflineQuizStore.quizResult.totalQuestions}</span>
          </div>

          <div className="mt-24 quiz-tip">
            <IconInfo />
            <span className="quiz-text-small quiz-font-500">{t('OfflineQuiz.fast')}</span>
          </div>

          <Button className="mt-24" loading={loading} onClick={handlePlayAgainClick}>{t('Offline.playAgain')}</Button>

          {
            OfflineQuizStore.quiz.actionUrl
            ? (
              <div className="mt-24 w-full">
                {
                  OfflineQuizStore.quizResult.answeredQuestions !== OfflineQuizStore.quiz.totalQuestions
                  ? (
                      <div className="quiz-text-caption quiz-font-500">{t('Finish.fresh')}</div>
                    )
                  : null
                }
                <Button white className="mt-8" onClick={handleActionButtonClick}>{OfflineQuizStore.quiz.actionText}</Button>
              </div>
              )
            : null
          }
        </div>

        <div className="mt-24 align-left quiz-text-caption quiz-font-800">{t('Finish.leaderList')}</div>
        <div className="quiz-text-secondary align-left quiz-font-700" style={{ marginTop: 4 }}>{t('Finish.quiz') + ` "${OfflineQuizStore.quiz.title}"`}</div>
        <div className="quiz-text-caption align-left quiz-font-500" style={{ marginTop: 4 }}>{
          OfflineQuizStore.quizResult.leaders.length
            ? t('Finish.leaderListDescription')
            : <Trans
              i18nKey="Finish.leaderListEmptyDescription"
              components={{ br: <br /> }}
             />

        }</div>

        <div className="mt-16 quiz-finish-leader-list">
          {OfflineQuizStore.quizResult.leaders.map(lead => (
            <div className="quiz-finish-leader-item">
              <div className="quiz-text-caption quiz-font-800 text-truncate">{lead.firstName + ' ' + lead.lastName}</div>
              <IconCoins />
              <div className="quiz-text-caption quiz-finish-leader-item-result">{formatDigits(lead.quizScore)}&nbsp;Б</div>
            </div>
          ))}
        </div>

        <Button outline className="mt-24" onClick={handleBackClick}>{t('Finish.back')}</Button>
      </div>
    </Page>
  )
}

export default observer(OfflineQuizFinish);
