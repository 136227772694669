import React from 'react';
import { Router } from 'framework7/modules/router/router';

import './atoms/SettingsPromoCode.less';
import SettingsPromoCode from './atoms/SettingsPromoCode'

import { ReactComponent as CloseIcon } from 'assets/close.svg';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  $f7router: Router.Router;
  onClose?(): void
}

const SettingsPromoCodeDialog: React.FC<IProps> = ({ $f7router, onClose, ...etc }) => {
  return (
    <div {...etc} >
      <CloseIcon className="quiz-settings-promo-code-dialog-close" onClick={onClose} />
      <SettingsPromoCode $f7router={$f7router} inDialog={true} />
    </div>
  );
}

export default SettingsPromoCodeDialog;
