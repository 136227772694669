import { v4 as uuid } from 'uuid';

import { AnswerState, CorrectAnswered, ELang, OfflineQuizStatus, OfflineQuizCategory } from 'types'

import {
  TwirpVerifyPromoCodeResponse,
  TwirpLoginResponse,
} from './protocol/common'

import {
  GetNextQuestionError,
  TwirpGetNextQuestionError,
  TwirpGetNextQuestionResponse,
  TwirpGetNextQuizResponse,
  TwirpGetParticipantsResponse,
  TwirpMakeAnswerResponse,
} from './protocol/onlineQuiz'

import {
  TwirpGetNextOfflineQuestionResponse,
  TwirpGetOfflineFinishedQuizzesResponse,
  TwirpGetOfflineQuizzesResponse,
  TwirpMakeOfflineAnswerResponse
} from './protocol/offlineQuiz';

const delay = (delay = 100) => new Promise(r => setTimeout(r, delay));
const int = (maxValue: number): number => Math.floor(Math.random() * maxValue);
const bool = () => Math.random() > 0.5;
export const arrayItem = (items: Array<any>) => items[int(items.length)];

const variants = [
  {
    id: uuid(),
    text: 'Самокат'
  },
  {
    id: uuid(),
    text: 'Вертолет'
  },
  {
    id: uuid(),
    text: 'На тапочках'
  },
];

let index = 1;

const CACHE = {
  offline: {}
}

const TOTAL_QUESTIONS = 1

export const mock = async (method: string, data: any): Promise<any> => {
  /* eslint-disable */
  console.log(
      '%c'+new Date().toISOString().slice(11)+'%c mock.ts %c' + method,
      'color: white; font-weight: bold; background: BlueViolet',
      'color: magenta',
      'color: lime',
      data
  )

  switch (method) {
    case 'AcceptOffer':
    case 'LeaveLobby':
      await delay();
      return;
    case 'EnterLobby':
      await delay();
      return ;
      // return {
      //   error:  GetNextQuestionError.LOSE
      // };
    case 'GetCurrentTime': {
      await delay(10);
      return {
        current_time: (new Date()).toISOString(),
      }
    }
    case 'LogIn': {
      await delay();
      // throw 'user not found'

      const response: TwirpLoginResponse = {
        user: {
          first_name: 'Firstname',
          last_name: 'Lastname',
          phone: '77711234567',
          user_id: '123',
          lang: ELang.RU,
          promo_code: 'S2S43',
          is_notifications_enabled: Math.random() > 0.5,
          birth_date: '12.08.1992',
        }
      }
      return response;
    }
    case 'ChangeUserProfile': {
      await delay();
      const response: TwirpLoginResponse = {
        user: {
          first_name: 'Firstname',
          last_name: 'Lastname',
          phone: '77711234567',
          user_id: '123',
          lang: ELang.RU,
          promo_code: 'S2S43',
          is_notifications_enabled: false,
          birth_date: '12.08.1992',
          ...data,
        }
      }
      return response;
    }
    case 'SignUp':  {
      await delay();
      const response: TwirpLoginResponse = {
        user: {
          first_name: 'Firstname',
          last_name: 'Lastname',
          phone: '77711234567',
          user_id: '123',
          lang: ELang.RU,
          promo_code: 'S2S43',
          is_notifications_enabled: false,
          birth_date: '12.08.1992',
        }
      }
      return response;
    }
    case 'GetParticipants': {
      await delay();
      const response: TwirpGetParticipantsResponse = { count: int(10_000) };
      return response;
    }
    case 'GetNextQuiz': {
      await delay();
      const response: TwirpGetNextQuizResponse = {
        result: {
          title: 'Япония',
          description: 'Страна восходящего солнца Япония — кладезь утонченной экзотики ...',
          id: uuid(),
          name: 'sake',
          start_time: Number(new Date((Date.now() - 10_000))),
          question_count: 20,
          finished: false,
          user_accept_offer: false,
          offer: `
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
            
            Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..Страна восходящего солнца Япония — кладезь утонченной экзотики ..
          `,
        }
      }
      return response;
    }
    case 'GetNextQuestion': {
      await delay();
      // const errResonse: TwirpGetNextQuestionError = {
      //   error: GetNextQuestionError.LOSE
      // };
      //
      // return errResonse;
      const response: TwirpGetNextQuestionResponse = {
        result: {
          question_id: uuid(),
          life_count: 1,
          quiz_id: data.quiz_id,
          question_text: 'На чем Сәке приехал на работу?',
          options: variants,
          start_time: Number(new Date((Date.now() + 3_000))),
          end_time: Number(new Date((Date.now() + 23_000))),
          question_index: index ++,
        }
      }

      return response;
    }
    case 'MakeAnswer': {
      await delay(2000);
      const correct = bool();

      const response: TwirpMakeAnswerResponse = {
        result: {
          correct,
          correct_answer:  arrayItem(variants),
          life_count: correct ? 1 : 0,
          next_question_start_time: Number(new Date((Date.now() + 20_000))),
          state:  correct ? AnswerState.PASSED : AnswerState.BURNED_LIFE,
          passThreshold: false,
          answeredCount: 12,
        }
      }
      return response;
    }
    case 'GetCorrectAnswered': {
      await delay();
      const response: CorrectAnswered = {
        count: int(200),
      };
      return response;
    }

    case 'VerifyPromoCode': {
      await delay();
      const response: TwirpVerifyPromoCodeResponse = {
        valid: false
      };
      return response;
    }

    case 'GetCurrentOfflineQuizzes': {
      await delay()

      // return {
      //   online_quiz_planning: true,
      // }

      const count = int(99)


      const response: TwirpGetOfflineQuizzesResponse = {
        online_quiz_planning: false,
        user_attempts: 1 + int(30),
        user_total_score: int(9999),
        quizzes: Array.apply(null, ({ length: count } as any)).map((_, index) => ({
          id: uuid(),
          name: uuid(),
          title: 'Япония',
          description: 'Страна восходящего солнца Япония — кладезь утонченной экзотики ...',
          image_url: 'https://placeimg.com/1000/800/any',
          total_questions: TOTAL_QUESTIONS,
          answered_questions: index % 2 ? int(20) : undefined,
          personal_score: index % 2 ? int(999) : undefined,
          top_answered_questions: index % 2 ? int(20) : undefined,
          top_answered_players: 0,//int(999),
          status: OfflineQuizStatus.NEW,
          last_answered_question: undefined, // TODO: ??
          action_url: 'https://ya.ru',
          action_text: 'test',
          popular: false,
          category: OfflineQuizCategory.SERIES, //arrayItem(Object.keys(OfflineQuizCategory)),
          new: bool(),
          rate: bool() ? int(50) / 10 : 0,
          user_rate_quiz: false,
        }))
      }

      return response
    }

    case 'GetFinishedOfflineQuizzes': {
      await delay()

      const count = int(99)

      const response: TwirpGetOfflineFinishedQuizzesResponse = {
        finished_count: count,
        quizzes: Array.apply(null, ({ length: count } as any)).map((_, index) => ({
          id: uuid(),
          name: uuid(),
          title: 'Япония',
          description: 'Страна восходящего солнца Япония — кладезь утонченной экзотики ...',
          image_url: 'https://placeimg.com/1000/800/any',
          total_questions: TOTAL_QUESTIONS,
          answered_questions: index % 2 ? int(20) : undefined,
          personal_score: index % 2 ? int(999) : undefined,
          top_answered_questions: index % 2 ? int(20) : undefined,
          top_answered_players: int(999),
          status: OfflineQuizStatus.FINISHED,
          last_answered_question: undefined, // TODO: ??
          popular: bool(),
          category: arrayItem(Object.keys(OfflineQuizCategory)),
          rate: bool() ? int(50) / 10 : 0,
          user_rate_quiz: false,
        }))
      }

      return response
    }

    case 'GetNextOfflineQuestion': {
      await delay()

      const response: TwirpGetNextOfflineQuestionResponse = {
        result: {
          quiz_id: data.quiz_id,
          question_text: 'На чем Сәке приехал на работу?',
          options: variants,
          question_id: uuid(),
          question_index: index ++,
          fact_id: uuid(),
        }
      }

      return response
    }

    case 'MakeOfflineAnswer': {
      await delay()

      const dur = data.duration

      const score = dur < 5999
        ? 5
        : dur < 10999
          ? 4
          : dur < 15999
            ? 3
            : dur < 20999
              ? 2
              : 1

      const correct = bool()

      const finished = index > TOTAL_QUESTIONS /* && bool() */

      const response: TwirpMakeOfflineAnswerResponse = {
        result: {
          correct,
          correct_answer: arrayItem(variants),
          answer_score: correct ? score : 0,
          quiz_finished: finished,
          answered_questions: finished ? int(index) : undefined,
          total_questions: TOTAL_QUESTIONS,
          personal_score: finished ? int(999) : undefined,
          leaders: finished ? Array.apply(null, ({ length: 0 } as any)).map((_, index) => ({
            first_name: 'FirstName'.repeat(25),
            last_name: 'LastName'.repeat(25),
            quiz_score: 100 - index * 10 + int(10),
          })) : undefined,
        }
      }

      return response
    }
  }
}





