import { action, configure, makeObservable, observable, runInAction } from 'mobx';
import i18next from 'i18next';
import aituBridge from '@btsd/aitu-bridge';
import { logAmplitude, setAmplitudeUserProperties } from 'utils/amplitude';

import api from 'api';
import {
  SignupRequest,
  UserInfo,
} from 'types';
import Framework7 from 'framework7/components/app/app-class';

import correctAnswerAudio from 'assets/audio/correct-answer.mp3';
import incorrectAnswerAudio from 'assets/audio/incorrect-answer.mp3';
import winAudio from 'assets/audio/win.mp3';
import { getUserProfile } from "../api/protocol/common";

configure({ useProxies: 'ifavailable' }); // For Android 6.0.

type AsyncReturnType<T extends (...args: any) => any> =
  T extends (...args: any) => Promise<infer U> ? U :
    T extends (...args: any) => infer U ? U :
      any

class Store {
   constructor() {
     makeObservable(this);

     if (process.env.REACT_APP_DEV) {
      (window as any)['$Store'] = this;
    }

    const initSound = () => {
      console.log('Store init sound');

      this.audio = {
        correctAnswer: new Audio(correctAnswerAudio),
        incorrectAnswer: new Audio(incorrectAnswerAudio),
        win: new Audio(winAudio),
      };

      window.removeEventListener('click', initSound);
    }

    window.addEventListener('click', initSound);

    this.muted = Boolean(localStorage.getItem('muted'))
  }

  audio: {
    [key: string]: HTMLAudioElement;
  } = {};

  app: Framework7 | null = null;

  me: UserInfo | null = null;
  aituMe: AsyncReturnType<typeof getUserProfile> | null = null;
  @observable muted = false
  @observable promoCodesEnabled = false

  setApp(app: Framework7) {
    this.app = app;
  }

  @action.bound mute(value: boolean) {
    localStorage.setItem('muted', value ? '1' : '')
    this.muted = value
  }

  logError(error: any, data: any, options: { callback?: () => any, eventName?: string; silent?: boolean } = {}) {
    const { name, message } = error;

    const eventName = options.eventName ?? 'tvquiz_app_error';

    logAmplitude(eventName, {
      name,
      message,
      ...data,
      rawError: JSON.stringify(error),
      userAgent: window.navigator?.userAgent || 'No user-agent',
    });
    if (!options.silent) {
      localStorage?.setItem('panicked', 'yes');

      this.app?.dialog.alert(
        i18next.t('common.unexpectedErrorText', { support: '@support_contest', token: (window as any).ONE_TIME_TOKEN}),
        i18next.t('common.errorDialogTitle') + (eventName !== 'tvquiz_app_error' ? ` (${eventName})`: ''),
        () => {
          if (options.callback) options.callback();
        }
      );
    }
  }

  async switchLanguage(newLanguage: 'ru' | 'kk') {
    setAmplitudeUserProperties({ tvquiz_app_language: newLanguage });
    return i18next.changeLanguage(newLanguage);
  };

  async getUserProfile(): Promise<UserInfo | null> {
    const user = await api.getUserProfile()
    this.me = user;
    this.aituMe = user;

    return user;
  }

  async editProfile(data: Partial<UserInfo>) {
    try {
      this.me = await api.changeUserProfile({ ...this.me!, ...data });
    } catch (error) {
      this.logError(error, { type: 'api', method: 'ChangeUserProfile' });
    }
  }

  async verifyPromoCode(code: string): Promise<boolean> {
    try {
      return api.verifyPromoCode(code);
    } catch (e) {
      this.logError(e, { type: 'api', method: 'VerifyPromoCode'}, { silent: true })
      return false;
    }
  }

  async playSound(name: string, muted = false): Promise<void> {
    if (this.muted) {
      return
    }

    const audio = this.audio[name];
    if (!audio) return;
    audio.muted = muted;
    audio.load();
    try {
      await audio.play();
    } catch (e) {
      this.logError(e, {}, { silent: true, eventName: 'tvquiz_app_media_error' });
    }
  }

  async toggleNotifications(on: boolean) {
    if (this.me!.isNotificationsEnabled === on || !aituBridge.isSupported()) {
      return;
    }

    await (
      on
        ? aituBridge.enableNotifications()
        : aituBridge.disableNotifications()
    )

    await this.editProfile({ isNotificationsEnabled: on })
  }
}

export default new Store();
