function preloadSequentially(baseSrc: string, ...nextSrcs: Array<string>) {
  const baseImg = new Image();
  if (nextSrcs.length > 0) {
    const [newBaseUrl, ...newNextSrcs] = nextSrcs;
    baseImg.onload = function() {
      preloadSequentially(newBaseUrl, ...newNextSrcs);
    };
  }
  baseImg.src = baseSrc;
}

function preloadParallel(baseSrc: string, ...nextSrcs: Array<string>) {
  const baseImg = new Image();
  baseImg.onload = function() {
    nextSrcs.forEach((nextSrc) => {
      const nextImg = new Image();
      nextImg.src = nextSrc;
    });
  };
  baseImg.src = baseSrc;
}

export { preloadSequentially, preloadParallel };
