import React, { useState } from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';
import aituBridge from '@btsd/aitu-bridge';

import api from 'api';
import { OnlineQuizStore, Store } from 'store';
import { Button } from 'components';
import { delay } from 'utils/common';
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const DevTools: React.FC<IProps> = ({ $f7router }) => {
  const [ loadingStartQuiz, setLoadingStartQuiz ] = useState(false);

  const handleStartQuiz = async () => {
    setLoadingStartQuiz(true);
    try {
      await api.startQuiz();
      await delay(500);
      await OnlineQuizStore.initQuizService()
      await OnlineQuizStore.quizService.fetchQuiz();
      setLoadingStartQuiz(false)
      $f7router.back('/play', { force: true })
    } catch (err) {
      $f7router.app.dialog.alert(
        '[API]: StartQuiz',
        `
          Please, show this to devs:
          Message: ${err.message}
          Raw: ${JSON.stringify(err)}
        `,
      );

      setLoadingStartQuiz(false)
    }
  }

  const handleChangePhone = async () => {
    if (aituBridge.isSupported()) {
      $f7router.app.toast.show({
        text: 'Error: Aitu bridge is active',
        position: 'top',
        closeTimeout: 2000,
        destroyOnClose: true,
      });
      return
    }

    let phone = ''

    do {
      phone = prompt(`Current phone: ${Store.me!.phone}\n Enter new phone number (11 digits): `, phone) ?? phone;
    } while (phone && !/^\d{11}$/.test(phone));

    if (!phone) {
      return;
    }

    localStorage.phone = phone;
    window.location.reload();
  }

  const handleLogout = async () => {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <Page>
      <div className="quiz-top-text-container quiz-settings">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">Dev tools</span>
        <Button outline onClick={handleStartQuiz} loading={loadingStartQuiz}>Start Quiz</Button>
        <Button outline onClick={handleChangePhone}>Change Phone</Button>
        <Button outline onClick={handleLogout}>Logout</Button>
      </div>
      <div className="quiz-bottom-container">
        <Button onClick={() => $f7router.back('/settings')}>Back</Button>
      </div>
    </Page>
  );
}

export default DevTools;
