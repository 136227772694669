import React from 'react'
import { Router } from 'framework7/modules/router/router'
import { useTranslation } from 'react-i18next'

import dialogs from 'dialogs'
import { ReactComponent as IconCoins } from 'assets/coins.svg'
import { ReactComponent as IconSettings } from 'assets/icon-settings.svg'
import { ReactComponent as IconRecent } from 'assets/recent.svg'
import { ReactComponent as IconStar } from 'assets/star.svg'
import { ReactComponent as IconAdd } from 'assets/add.svg'

import './OfflineTopBar.less'
import { logAmplitude } from 'utils/amplitude';
import { Store } from 'store';

interface IProps extends React.HTMLAttributes<HTMLDivElement>{
  $f7router: Router.Router
  attempts: number
  points: number
}

const OfflineTopBar: React.FC<IProps> = ({ $f7router, attempts, points, className, ...etc }) => {
  const { t } = useTranslation()

  const handleAttemptsAddClick = () => {
    logAmplitude('tvquiz_app_quiz_addtry_clicked')
    dialogs.promoCodeDialog.open()
  }

  const handleRecentClick = () => {
    $f7router.navigate('/offline-history')
  }

  const handleSettingsClick = () => {
    $f7router.navigate('/settings')
  }

  return (
    <div className={'quiz-offline-top-bar ' + className } {...etc}>

      <div className="quiz-offline-top-bar-attempts">
        <div className="quiz-text-small quiz-font-500 quiz-offline-top-bar-caption">{t('Offline.attempts')}</div>
        <div className="quiz-offline-top-bar-attempts__icons">
          <div>
            <IconStar />
            <span className="quiz-text-secondary quiz-font-700 quiz-offline-top-bar-value">{attempts}</span>
          </div>
          {
            Store.promoCodesEnabled &&
            <IconAdd style={{ marginLeft: 16 }} onClick={handleAttemptsAddClick} />
          }
        </div>
      </div>

      <div className="quiz-offline-top-bar-points">
        <div className="quiz-text-small quiz-font-500 quiz-offline-top-bar-caption">{t('Offline.points')}</div>
        <div>
          <IconCoins />
          <span className="quiz-text-secondary quiz-font-700 quiz-offline-top-bar-value">{points}</span>
        </div>
      </div>

      <IconRecent className="quiz-offline-top-bar-recent" onClick={handleRecentClick} />

      <IconSettings className="quiz-offline-top-bar-settings" onClick={handleSettingsClick} />
    </div>
  )
}

export default OfflineTopBar



