import Framework7 from 'framework7';
import React from 'react';
import { Router } from 'framework7/modules/router/router';
import i18next from 'i18next';

import {
  DevTools,
  Welcome,
  Settings,
  Start,
  SettingsLanguage,
  SettingsPromoCode,
  Offers,
  OnlineQuizMain,
  OnlineQuiz,
  OfflineQuizList,
  OfflineQuiz,
  OfflineQuizListHistory,
  OfflineQuizFinish,
  SettingsFAQ,
} from 'screens';
import { OfflineQuizStore, OnlineQuizStore, Store } from 'store';
import { initAmplitude, logAmplitude } from 'utils/amplitude';
import { initLanguage } from './i18n';
import { startBrowserBack, stopBrowserBack } from './utils/common';
import CONSTANTS from 'consts';
import Registration from './screens/Registration/Registration';

const tellIfPanicked = (app: Framework7) => {
  const panicked = localStorage?.getItem('panicked');
  if (panicked) {
    localStorage?.removeItem('panicked');

    app.dialog.alert(
      i18next.t('common.aboutRecoveryAfterPanic'),
      i18next.t('common.justAMoment'),
    );
  }
};

const attachRouterEvents = (router: Router.Router) => {

  // I LOVE F7 ROUTER SO MUCH <3 <3 <3
  const handleBrowserBackBug = (shouldCheck: boolean) => {
    if (!shouldCheck) return

    setTimeout(() => {
      if (!router.allowPageChange && (router as any).currentPageEl.classList.contains('page-previous')) {
        const currentPageEl: HTMLElement = (router as any).currentPageEl

        currentPageEl.classList.replace('page-previous', 'page-current')
        router.el.classList.remove('router-transition-backward', 'router-transition')
        router.allowPageChange = true

        console.warn('Router transition have been ended manually')
      }

    }, 200)
  }

  router.on('routeChange', (newRoute, previousRoute) => {
    if (previousRoute.path === '/offline' || previousRoute.path === '/online') {
      startBrowserBack()
    }

    if (newRoute.path === '/offline') {
      OfflineQuizStore.reset()
      OfflineQuizStore.getQuizzes()
        .then(isOnlineQuizPlanning => {
          if (isOnlineQuizPlanning) {
            router.navigate('/play')
            return false
          }
          return true
        })
        .catch(() => true)
        .then(handleBrowserBackBug)

      stopBrowserBack()
    }

    if (newRoute.path === '/online') {
      stopBrowserBack()
    }


    if (newRoute.path === '/offline-history') {
      logAmplitude('tvquiz_app_history_opened')
      OfflineQuizStore.reset()
      OfflineQuizStore.getFinishedQuizzes()
        .then(() => true)
        .catch(() => true)
        .then(handleBrowserBackBug)
    }

  })
}

const decideWhereRedirectTo = async (router: Router.Router, reject: Function) => {

  try {
    Store.setApp(router.app);
    const isLoggedIn = await Store.getUserProfile();

    if (isLoggedIn === null) {
      return;
    }

    const hasAitubeQuiz = window.location.href.includes("aitubeQuiz");
    if (hasAitubeQuiz) {
      OfflineQuizStore.aitubeQuiz = true;
    }

    attachRouterEvents(router)

    initAmplitude(Store.me!.userId);
    logAmplitude('tvquiz_app_time_start', { time: (new Date()).toISOString() });

    // Start
    if (!isLoggedIn) {
      reject();
      router.navigate('/start');
      return;
    }

    reject();
    router.navigate('/play');

    return;
  } catch (error) {
    console.log('init error');
    console.log(error);
  }
};

const routes: Router.RouteParameters[] = [
  {
    path: '/',
    async: async function(routeTo, routeFrom, resolve, reject) {
      const router = this as unknown as Router.Router;

      await initLanguage()

      tellIfPanicked(router.app);

      await decideWhereRedirectTo(router, reject);
    },
  },
  {
    path: '/play',
    async: async function(routeTo: Router.Route, routeFrom: Router.Route, resolve: Function, reject: Function) {
      document.body.classList.add('loader')

      const router = this as unknown as Router.Router;

      const isOnlineQuizPlanning = await OfflineQuizStore.getQuizzes(true)

      if (isOnlineQuizPlanning) {
        // Online quiz init:
        await OnlineQuizStore.initQuizService();

        reject();
        router.navigate('/online');
      } else {
        OfflineQuizStore.app = router.app

        let offlineQuizRoute = '/offline'
        const props: any = {}

        const url = new window.URL(window.location.href)

        // handle query quiz:
        const queryQuizId = url.searchParams.get(CONSTANTS.quizQueryParam)

        if (queryQuizId) {

          if (OfflineQuizStore.quizzes?.some(quiz => quiz.id === queryQuizId)) {

            props.selectQuiz = queryQuizId

          } else {
            await OfflineQuizStore.getFinishedQuizzes()

            if (OfflineQuizStore.finishedQuizzes?.some(quiz => quiz.id === queryQuizId)) {
              offlineQuizRoute = '/offline-history'
              props.selectQuiz = queryQuizId
            }
          }

          url.searchParams.delete(CONSTANTS.quizQueryParam)
          window.history.replaceState({}, window.document.title, url.href)
        }

        reject();

        router.navigate(offlineQuizRoute, { props });
      }

      document.body.classList.remove('loader')
    },
  },
  { path: '/dev', component: DevTools },
  { path: '/online', component: OnlineQuizMain },
  { path: '/online-quiz', component: OnlineQuiz },
  { path: '/welcome', component: Welcome, options: { pushState: false } },
  { path: '/start', component: Start, options: { pushState: false } },
  { path: '/settings', component: Settings },
  { path: '/settings-language', component: SettingsLanguage },
  { path: '/settings-promo-code', component: SettingsPromoCode },
  { path: '/settings-profile', component: Registration, options: { props: { profile: true }} },
  { path: '/offers', component: Offers },
  { path: '/offline', component: OfflineQuizList },
  { path: '/offline-history', component: OfflineQuizListHistory },
  { path: '/offline-quiz', component: OfflineQuiz },
  { path: '/offline-quiz-finish', component: OfflineQuizFinish , options: { pushState: false }},
  { path: '/settings-faq', component: SettingsFAQ },
];

export default routes;
