import type { ELang, MeSignInfo, PhoneSignInfo, SignupRequest, UserInfo } from 'types';
import { makeRequest } from '../request';
import OPTIONS from '../options'

// ======================================================================
// ============================= LOGIN ==================================
// ======================================================================


interface TwirpLoginRequest {
  phone: string;
  phoneSignInfo: PhoneSignInfo;
}

export interface TwirpLoginResponse {
  user: {
    first_name: string
    last_name: string;
    phone: string;
    user_id: string;
    lang: ELang;
    promo_code: string;
    is_notifications_enabled: boolean;
    birth_date: string;
  };
}

export const login = (phone: string, phoneSignInfo: PhoneSignInfo): Promise<UserInfo> => {
  return makeRequest<TwirpLoginRequest, TwirpLoginResponse>(
    'LogIn',
    { phone, phoneSignInfo }
  ).then(({ user }) => ({
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    userId: user.user_id,
    lang: user.lang,
    promoCode: user.promo_code,
    isNotificationsEnabled: user.is_notifications_enabled,
    birthDate: user.birth_date,
  }));
}

// ======================================================================
// ======================= CHANGE USER PROFILE ==========================
// ======================================================================

interface TwirpChangeUserProfileRequest {
  userId: string;
  phone: string;
  firstName: string;
  lastName: string;
  lang: string;
  is_notifications_enabled: boolean;
  birth_date: string; // format dd.mm.yyyy
}

export const changeUserProfile = (data: UserInfo): Promise<UserInfo> => {
  const { isNotificationsEnabled: is_notifications_enabled, birthDate: birth_date, promoCode, ...userInfo } = data;

  return makeRequest<TwirpChangeUserProfileRequest, TwirpLoginResponse>(
    'ChangeUserProfile',
    {
      ...userInfo,
      is_notifications_enabled,
      birth_date,
    }
  ).then(({ user }) => ({
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    userId: user.user_id,
    lang: user.lang,
    promoCode: user.promo_code,
    isNotificationsEnabled: user.is_notifications_enabled,
    birthDate: user.birth_date,
  }));
}

// ======================================================================
// ============================== SIGNUP ================================
// ======================================================================

interface TwirpSignupRequest {
  user: {
    first_name: string
    last_name: string;
    phone: string;
    user_id: string;
    lang: ELang;
    is_notifications_enabled: boolean;
    birth_date: string; // format dd.mm.yyyy
  };
  phoneSignInfo: PhoneSignInfo;
  meSignInfo: MeSignInfo;
  registration_promo_code?: string;
}

export const signup = (data: SignupRequest, meSignInfo: MeSignInfo, phoneSignInfo: PhoneSignInfo): Promise<UserInfo> => {
  return makeRequest<TwirpSignupRequest, TwirpLoginResponse>(
    'SignUp',
    {
      registration_promo_code: data.promoCode,
      meSignInfo,
      phoneSignInfo,
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        user_id: data.userId,
        lang: data.lang,
        is_notifications_enabled: data.isNotificationsEnabled,
        birth_date: data.birthDate,
      }
    }
  ).then(({ user }) => ({
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    userId: user.user_id,
    lang: user.lang,
    promoCode: user.promo_code,
    isNotificationsEnabled: user.is_notifications_enabled,
    birthDate: user.birth_date,
  }));
};

// ======================================================================
// =========================== GET CURRENT TIME =========================
// ======================================================================

interface TwirpGetCurrentTimeResponse {
  current_time: string;
}

export const getCurrentTime = (log = false): Promise<string> => {
  return makeRequest<{}, TwirpGetCurrentTimeResponse>(
    'GetCurrentTime',
    {},
    log ? `?time=${(new Date(Date.now() + OPTIONS.timeOffset)).toISOString()}` : ''
  ).then(response => response ? response.current_time : '');
}


// ======================================================================
// =========================== VERIFY PROMO CODE ========================
// ======================================================================

interface TwirpVerifyPromoCodeRequest {
  promo_code: string;
}

export interface TwirpVerifyPromoCodeResponse {
  valid: boolean;
}

export const verifyPromoCode = (promo_code: string): Promise<boolean> => {
  return makeRequest<TwirpVerifyPromoCodeRequest, TwirpVerifyPromoCodeResponse>(
    'VerifyPromoCode',
    { promo_code },
  ).then(({ valid }) => valid);
}

// ======================================================================
// =========================== SET PROMO CODE ===========================
// ======================================================================

interface TwirpSetPromoCodeRequest {
  phone: string;
  promo_code: string;
}

interface TwirpSetPromoCodeResponse {
  old_promo_code: string;
  new_promo_code: string;
}

export const setPromoCode = (phone: string, promo_code: string): Promise<TwirpSetPromoCodeResponse> => {
  return makeRequest<TwirpSetPromoCodeRequest, TwirpSetPromoCodeResponse>(
    'SetPromoCode',
    { phone, promo_code },
  );
}

// ======================================================================
// =========================== ACCEPT OFFER =============================
// ======================================================================

interface TwirpAcceptOfferRequest {
  quiz_id: string;
}

export const acceptOffer = (quiz_id: string): Promise<void> => {
  return makeRequest<TwirpAcceptOfferRequest, void>(
    'AcceptOffer',
    { quiz_id },
  );
}


// ======================================================================
// ======================== [DEV]: START QUIZ ===========================
// ======================================================================

export const startQuiz = (): Promise<void> => {
  return makeRequest(
    'StartQuiz'
  );
}

export interface TwirpGetPassportOAuthUrlRequest {
  redirect_url: string
}

export interface TwirpGetPassportOAuthUrlResponse {
  url: string
}

export const getPassportAuthUrl = async (redirect = window.location.href) => {
  const apiRequest = await makeRequest<TwirpGetPassportOAuthUrlRequest, TwirpGetPassportOAuthUrlResponse>(
    'GetPassportOAuthUrl',
    { redirect_url: redirect },
  );

  return apiRequest.url
}

export const getUserProfile = async () => {
  return await makeRequest<{}, TwirpLoginResponse>(
    'GetUserProfile'
  ).then(({ user }) => ({
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    userId: user.user_id,
    lang: user.lang,
    promoCode: user.promo_code,
    isNotificationsEnabled: user.is_notifications_enabled,
    birthDate: user.birth_date,
  }));
}
