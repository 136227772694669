import differenceInMilliseconds from "date-fns/esm/differenceInMilliseconds";
import subSeconds from "date-fns/esm/subSeconds";

import api from 'api'
import type { AnswerResult, Question, Quiz } from 'types'
import { AnswerState } from 'types'

import { delay, randomInt, parseControlChars } from 'utils/common';
import { methodSlip } from 'utils/slip';
import { clearTimeout, setTimeout } from 'utils/timeout';

import QuizService, { EQuizError, QuizError } from './quizService';

export default class QuizServiceHttp extends QuizService {
  private isConnected = false;

  question?: Question;
  answerResult?: AnswerResult;
  quiz!: Quiz;
  private lifeCount = 0;

  async start() {
    this.getNextQuiz();
    this.startGetNextQuizInterval();
  }

  async connect() {
    if (!this.quiz) {
      this.handleError({ type: EQuizError.inner, message: 'QUIZ_NOT_FOUND' })
      return;
    }

    this.disconnect(true);
    this.stopGetNextQuizInterval();

    const isConnectionSuccess = await this.enterLobby();

    if (!isConnectionSuccess) {
      return;
    }

    // this.startParticipants();
    this.getNextQuestion(subSeconds(this.quiz.startTime, 3));

    this.isConnected = true;
  }

  disconnect(reconnect = false) {
    clearTimeout(this.getNextQuestionTimeout);
    clearTimeout(this.getCorrectAnsweredTimeout);

    if (!reconnect) {
      this.leaveLobby();
      this.stopParticipantsInterval();

      this.startGetNextQuizInterval();
      this.isConnected = false;
      this.emit('disconnect');
    }
  }

  async makeAnswer(quizId: string, questionId: string, answerId?: string) {
    const method = 'MakeAnswer';
    await delay(randomInt(1000)); // jitter


    try {
      const answerResult = await methodSlip(
        api.onlineQuiz.makeAnswer,
        [{ quizId, questionId, answerId }],
        {
          onAttempt: (attempt) => { if (attempt) this.handleError({ type: EQuizError.network, message: 'attempt', method, data: { attempt }})},
          onError: (error, attempt, errorAttempt) => this.handleError({ type: EQuizError.api, method, error, data: { attempt, errorAttempt }})
        });

      this.handleAnswer(answerResult);
    } catch (error) {
      this.handleError({ type: EQuizError.network, message: 'unknown', error });
    }
  }

  async fetchQuiz() {
    await this.getNextQuiz()
  }

  protected getParticipantsInterval: number = 0;
  protected getNextQuizInterval: number = 0;

  protected getNextQuestionTimeout = 0;
  protected getCorrectAnsweredTimeout: number = 0;

  constructor() {
    super();
  }

  protected handleError(error: QuizError) {
    this.emit('error', error);
  }

  private startGetNextQuizInterval() {
    this.getNextQuizInterval = window.setInterval(
      () => this.getNextQuiz(),
      20_000);
  }

  private stopGetNextQuizInterval() {
    window.clearInterval(this.getNextQuizInterval);
    this.getNextQuizInterval = 0;
  }

  private async getNextQuiz() {
    try {
      const quiz = await api.onlineQuiz.getNextQuiz();

      if (this.isConnected) {
        this.stopGetNextQuizInterval();
        return;
      }

      if (quiz && this.quiz?.id !== quiz?.id) {
        quiz.title = parseControlChars(quiz.title)
        quiz.description = parseControlChars(quiz.description)
        quiz.offer = parseControlChars(quiz.offer, { newLinePadding: true })
        this.quiz = quiz;
      }

      this.emit('quiz', quiz || null);

    } catch (error) {
      if (error.code === 'not_found') {
        this.emit('quiz',null);
        return;
      }

      this.handleError({ type: EQuizError.api, method: 'GetNextQuiz', data: { error } })
    }
  }

  protected async getNextQuestion(then: Date) {
    const method = 'GetNextQuestion';

    this.getNextQuestionTimeout = setTimeout(
      async () => {
        try {
          const result = await methodSlip(
            api.onlineQuiz.getNextQuestion,
            [{ quizId: this.quiz.id}],
            {
              onAttempt: (attempt) => { if (attempt) this.handleError({ type: EQuizError.network, message: 'attempt', method, data: { attempt }})},
              onError: (error, attempt, errorAttempt) => this.handleError({ type: EQuizError.api, method, data: { error, attempt, errorAttempt }})
            }
          );

          if ('error' in result) {
            const error = result.error;

            switch (error) {
              case api.onlineQuiz.GetNextQuestionError.EARLY:
                this.getNextQuestion(new Date());
                break;
              case api.onlineQuiz.GetNextQuestionError.LATE:
                console.log('[QuizServiceHttp]', api.onlineQuiz.GetNextQuestionError.LATE)
                // this.handleError({ type: EQuizError.logic, method, message: GetNextQuestionError.LATE });
                break;
              case api.onlineQuiz.GetNextQuestionError.LOSE:
                this.handleError({ type: EQuizError.logic, method, message: api.onlineQuiz.GetNextQuestionError.LOSE });
                this.disconnect();
                break;
            }

            return;
          } else {
            this.handleLifeCount(result.lifeCount);
            this.handleQuestion(result.question);
          }
        } catch (error) {
          // if slip failed then it is almost network error, so retry
          this.handleError({ type: EQuizError.network, message: 'unknown', data: { error }});
          this.getNextQuestion(new Date());
        }
      },
      differenceInMilliseconds(then, new Date()));
  }

  protected async getCurrentAnswered(questionId: string) {
    if (!this.quiz) return;

    try {
      const result = await api.onlineQuiz.getCorrectAnswered({ questionId, quizId: this.quiz.id });
      this.emit('currentAnswered', questionId, result.count);
    } catch (error) {
      this.handleError({ type: EQuizError.api, method: 'GetCorrectAnswered', data: { error } });
    }
  }

  protected async getWinners() {
    if (!this.quiz) return;

    try {
      const result = await api.onlineQuiz.getWinners({ quizId: this.quiz.id });
      this.emit('winners', result.count);
    } catch (error) {
      this.handleError({ type: EQuizError.api, method: 'GetWinners', data: { error } });
    }
  }

  private startParticipants() {
    this.getParticipants();
    this.getParticipantsInterval = window.setInterval(
      () => this.getParticipants(),
      5_000
    );
  }

  private stopParticipantsInterval() {
    window.clearInterval(this.getParticipantsInterval);
    this.getParticipantsInterval = 0;
  }

  private async getParticipants() {
    try {
      const { peopleCount } = await api.onlineQuiz.getParticipants(this.quiz.id);
      this.emit('participants', peopleCount);
    } catch (error) {
      this.handleError({ type: EQuizError.api, method: 'GetParticipants', data: { error } })
    }
  }

  private async enterLobby(): Promise<boolean> {
    const method = 'EnterLobby';

    try {
      const result = await methodSlip(
        api.onlineQuiz.enterLobby,
        [this.quiz.id],
        {
          onAttempt: (attempt) => { if (attempt) this.handleError({ type: EQuizError.network, message: 'attempt', method, data: { attempt }})},
          onError: (error, attempt, errorAttempt) => this.handleError({ type: EQuizError.api, method, data: { error, attempt, errorAttempt }})
        });

      if (!result) {
        return true;
      }

      if ('error' in result) {
        const error = result.error;

        switch (error) {
          case api.onlineQuiz.GetNextQuestionError.LOSE:
            this.handleError({ type: EQuizError.logic, method, message: api.onlineQuiz.GetNextQuestionError.LOSE });
            return false
        }
      }

      return true;

    } catch (error) {
      this.handleError({ type: EQuizError.network, message: 'unknown', data: { error } });
      return false;
    }
  }

  private async leaveLobby() {
    const method = 'LeaveLobby';

    try {
      await methodSlip(
        api.onlineQuiz.leaveLobby,
        [this.quiz.id],
        {
          onAttempt: (attempt) => { if (attempt) this.handleError({ type: EQuizError.network, message: 'attempt', method, data: { attempt }})},
          onError: (error, attempt, errorAttempt) => this.handleError({ type: EQuizError.api, method, data: { error, attempt, errorAttempt }})
        });

    } catch (error) {
      this.handleError({ type: EQuizError.network, message: 'unknown', data: { error } });
    }
  }

  protected handleLifeCount(lifeCount: number) {
    if (lifeCount !== this.lifeCount) {
      this.lifeCount = lifeCount;
      this.emit('lifeCount', this.lifeCount);
    }
  }

  protected async handleQuestion(question: Question) {
    if (this.question?.id === question.id) return;

    this.question = question;

    await delay(differenceInMilliseconds(question.startTime, new Date()));
    this.emit('question', question);
  }

  private async handleAnswer(answerResult: AnswerResult) {
    if (this.answerResult?.questionId === answerResult.questionId) return;

    this.answerResult = answerResult;

    if ([ AnswerState.BURNED_LIFE, AnswerState.PASSED ].includes(answerResult.state)) {
      this.getCorrectAnsweredTimeout = setTimeout(() => {
        this.getCurrentAnswered(answerResult.questionId);
      }, 3000); // 3s timeout to get correct answered
    } else if (AnswerState.WIN === answerResult.state) {
      this.getCorrectAnsweredTimeout = setTimeout(() => {
        this.getWinners();
      }, 3000); // 3s timeout to get winners
    }

    this.handleLifeCount(answerResult.lifeCount);
    this.emit('answerResult', answerResult);

    if (answerResult.nextQuestionStartTime) {
      this.getNextQuestion(subSeconds(answerResult.nextQuestionStartTime, 3))
    }
  }

}
