import { f7 } from 'framework7-react';
import { render } from 'react-dom';
import i18next from 'i18next';
import aituBridge from '@btsd/aitu-bridge';

import { Button } from 'components'

const lateDialogInit = () => {
  const dialogInstance = f7.dialog.create({
    cssClass: 'quiz-settings-promo-code-dialog',
    closeByBackdropClick: false,
    title: '',
  })

  const handleSkip = async () => {
    aituBridge.isSupported() ? aituBridge.closeApplication() : f7.views.main.router.navigate('/start')
  }

  const handlePlay = () => {
    dialogInstance.close()
  }

  render(
    <div>
      <div className="quiz-top-text-container">
        <span className="quiz-text-primary quiz-font-700">{i18next.t('Late.title')}</span>
        <span className="quiz-text-caption quiz-font-500" style={{ marginTop: 12 }}>{i18next.t('Late.description')}</span>
      </div>

      <div className="quiz-container" style={{ padding: '0 16px' }}>
        <Button
          className="mt-24"
          onClick={handlePlay}
        >
          {i18next.t('Late.play')}
        </Button>

        <Button
          outline
          className="mt-16"
          onClick={handleSkip}
        >
          {i18next.t('Late.skip')}
        </Button>

        <div className="mt-24 quiz-text-small color-caption">{i18next.t('Late.caption')}</div>
      </div>
    </div>
    ,dialogInstance.el.children[0]
  )

  return dialogInstance
}

export default lateDialogInit
