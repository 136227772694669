import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import aituBridge from '@btsd/aitu-bridge';

export enum ELang {
  kk = 'kk',
  ru = 'ru',
}

export const LANGUAGES = [
  ELang.kk,
  ELang.ru,
]

export const DEFAULT_LANGUAGE = ELang.ru

export const isSupportedLanguage = (input: string): boolean => {
  return LANGUAGES.includes(input as any)
}

export const initLanguage = async () => {
  await i18nextInit

  const storageLanguage = aituBridge.isSupported() ? await aituBridge.storage.getItem('language') : localStorage.getItem('language')

  if (storageLanguage && isSupportedLanguage(storageLanguage)) {
    await i18next.changeLanguage(storageLanguage)
    return
  }

  const queryLanguage = (new URLSearchParams(window.location.search)).get('lang')

  if (queryLanguage && isSupportedLanguage(queryLanguage)) {
    aituBridge.isSupported() ? await aituBridge.storage.setItem('language', queryLanguage) : localStorage.setItem('language', queryLanguage)
    await i18next.changeLanguage(queryLanguage)
  }

  aituBridge.isSupported() ? await aituBridge.storage.setItem('language', DEFAULT_LANGUAGE) : localStorage.setItem('language', DEFAULT_LANGUAGE)
  await i18next.changeLanguage(DEFAULT_LANGUAGE)

  return
};

const i18nextInit = i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    fallbackLng: ['ru'],
    whitelist: LANGUAGES,
    supportedLngs: LANGUAGES,
    debug: process.env.REACT_APP_QUIZ_HOST !== 'https://tv-quiz.miniapps.aitu.io/twirp/kz.btsd.messenger.tvquiz.TvQuizService/',
    i18nFormat: {
      localeData: 'ru',
    },
  });
