import React from 'react';
import { OfflineQuizCategory } from 'types';
import { useTranslation } from 'react-i18next';

import airplane from './assets/airplane.png'
import birthdayCake from './assets/birthday_cake.png'
import car from './assets/car.png'
import checkMark from './assets/check_mark.png'
import girl from './assets/girl.png'
import guitar from './assets/guitar.png'
import language from './assets/language.png'
import newIcon from './assets/new.png'
import search from './assets/search.png'
import star from './assets/star.png'
import tv from './assets/tv.png'
import soccer from './assets/soccer.png'
import films from './assets/films.png'

import './CategorySelector.less'
import { logAmplitude } from '../../utils/amplitude';

const CategoryIcons: Record<OfflineQuizCategory, string> = {
  [OfflineQuizCategory.ALL]: checkMark,
  [OfflineQuizCategory.NEW]: newIcon,
  [OfflineQuizCategory.POPULAR]: star,
  [OfflineQuizCategory.LANGUAGES]: language,
  [OfflineQuizCategory.MUSIC]: guitar,
  [OfflineQuizCategory.DIFFERENT]: birthdayCake,
  [OfflineQuizCategory.FACTS]: search,
  [OfflineQuizCategory.SERIES]: tv,
  [OfflineQuizCategory.CARS]: car,
  [OfflineQuizCategory.FASHION]: girl,
  [OfflineQuizCategory.SPORT]: soccer,
  [OfflineQuizCategory.TRAVEL]:airplane,
  [OfflineQuizCategory.FILMS]: films,
}

const CategoryEvent: Record<OfflineQuizCategory, string> = {
  [OfflineQuizCategory.ALL]: 'tvquiz_all_categories',
  [OfflineQuizCategory.NEW]: 'tvquiz_new_categories',
  [OfflineQuizCategory.POPULAR]: 'tvquiz_popular_categories',
  [OfflineQuizCategory.LANGUAGES]: 'tvquiz_lang_categories',
  [OfflineQuizCategory.MUSIC]: 'tvquiz_music_categories',
  [OfflineQuizCategory.DIFFERENT]: 'tvquiz_different_categories',
  [OfflineQuizCategory.FACTS]: 'tvquiz_facts_categories',
  [OfflineQuizCategory.SERIES]: 'tvquiz_shows_categories',
  [OfflineQuizCategory.CARS]: 'tvquiz_cars_categories',
  [OfflineQuizCategory.FASHION]: 'tvquiz_style_categories',
  [OfflineQuizCategory.SPORT]: 'tvquiz_sport_categories',
  [OfflineQuizCategory.TRAVEL]: 'tvquiz_travel_categories',
  [OfflineQuizCategory.FILMS]: 'tvquiz_films_categories',
}


const CategoryList = Object.keys(CategoryIcons) as OfflineQuizCategory[]

interface IProps extends React.HTMLAttributes<HTMLDivElement>{
  selected?: OfflineQuizCategory
  onCategorySelect(c: OfflineQuizCategory): void
}

const CategorySelector: React.FC<IProps> = ({ selected, onCategorySelect, className = '', ...props }) => {
  const { t, i18n } = useTranslation();
  const selectedRef = React.useRef<HTMLDivElement | null>(null)


  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const category = e.currentTarget.dataset.category as OfflineQuizCategory

    logAmplitude(CategoryEvent[category])

    if (category === selected) {
      return
    }

    e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
    onCategorySelect(category)
  }

  React.useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
    }
  }, [])

  return (
    <div className={'quiz-cs ' + className} {...props}>
      {CategoryList.map(cat => (
        <div
          ref={cat === selected ? selectedRef : undefined}
          key={cat}
          className={'quiz-cs-item ' + (selected === cat ? 'quiz-cs-item-selected' : '')}
          data-category={cat}
          onClick={handleItemClick}
        >
          <img src={CategoryIcons[cat]} alt={t('Category.' + cat)}/>
          <span>{t('Category.' + cat)}</span>
        </div>
      ))}
    </div>
  );
}

export default CategorySelector;
