import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'framework7-react/components/page';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';

import { ReactComponent as IconHeart } from 'assets/icon-heart.svg';
import { OnlineQuizStore, Store } from 'store';
import { formatDigits } from 'utils/common';

import './OnlineQuiz.less';
import useOnlineQuiz from './useOnlineQuiz';
import dialogs from 'dialogs'
import api from 'api';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const OnlineQuiz: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    OnlineQuizStore.reset();
    OnlineQuizStore.leaveLobby();
    $f7router.back('/play');
  }, []);

  const onError = useCallback((error: string) => {

    if (error === api.onlineQuiz.GetNextQuestionError.LATE) {
      $f7router.app.dialog.alert(t('common.loseDialogDescription'), t('Late.title'));
    } else {
      $f7router.app.dialog.alert(t('common.loseDialogDescription'), t('common.loseDialogTitle'));
    }


    window.setTimeout(() => {
      OnlineQuizStore.reset();
      OnlineQuizStore.leaveLobby();
      $f7router.back('/play');
    }, 500); // temp hack to handle immediate navigate in falsy quiz cases

  }, []);

  useEffect(() => {
    OnlineQuizStore.enterLobby();
    // return () => { Store.leaveLobby() };
  }, []);

  const Body = useOnlineQuiz(OnlineQuizStore, Store, onClose, onError);
  // const Body = MOCK.win;

  useEffect(() => {
    if (OnlineQuizStore.question && OnlineQuizStore.question.index > 1) {
      dialogs.lateDialog.open()
    }
  }, [Boolean(OnlineQuizStore.question)])


  return (
    <Page>
      <div className="quiz-container">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <div className="quiz-lobby-widgets-container">
          <div
            className={'quiz-lobby-widget quiz-lobby-widget-life ' + (OnlineQuizStore.life ? '': 'quiz-lobby-no-more-life')}
            // hide life @ question above 16
            style={{ opacity: OnlineQuizStore.question ? (OnlineQuizStore.question.index > 15 ? 0 : 1) : 1}}
          >
            <div className="quiz-lobby-widget-caption">{t('common.lifes')}</div>
            <div className="quiz-lobby-widget-body">
              <IconHeart />
              <span>{OnlineQuizStore.life}</span>
            </div>
          </div>
          <div className="quiz-lobby-widget quiz-lobby-widget-people" style={{ visibility: 'hidden' }}>
            <div className="quiz-lobby-widget-caption">{t('common.nowPlaying')}</div>
            <div  className="quiz-lobby-widget-body">
              <span>{formatDigits(OnlineQuizStore.peopleInLobby)}</span>
            </div>
          </div>
        </div>
      </div>
      {Body}
    </Page>
  );
}

export default observer(OnlineQuiz);

