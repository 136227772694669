import { f7 } from 'framework7-react';
import { render } from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { Button } from 'components'
import ListInput from 'framework7-react/components/list-input';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as Star } from './assets/star.svg';
import { ReactComponent as StarActive } from './assets/starActive.svg';

import { OfflineQuizStore } from 'store';

import api from 'api'


import './styles.less'
import List from 'framework7-react/components/list';

const RateDialogBody: React.FC<{ close(): void, dialogInstance: ReturnType<typeof f7.dialog.create> }> = ({ close, dialogInstance }) => {
  const [ rate, setRate ] = useState(0)
  const [ error, setError ] = useState('')
  const inputRef = useRef<any>()

  const { t } = useTranslation();

  useEffect(() => {
    dialogInstance.on('closed', () => {
      inputRef.current!.refs.inputEl.value = ''
      setRate(0)
    })
  }, [])

  const handleSubmit = async () => {
    if (OfflineQuizStore.quiz) {
      const comment = inputRef.current?.domValue() ?? ''

        api.offlineQuiz.rateOfflineQuiz({
          quizId: OfflineQuizStore.quiz.id,
          rate,
          comment,
        }).then(() => {
          OfflineQuizStore.quiz!.userRateQuiz = true
        })
    }

    close()
  }

  return (
    <div>
      <div className="quiz-top-text-container">
        <span className="quiz-text-secondary quiz-font-700">{i18next.t('RateQuiz.title')}</span>
        <span className="quiz-text-caption quiz-font-500" style={{ marginTop: 4 }}>{i18next.t('RateQuiz.subTitle')}</span>

        <div className="mt-24 quiz-rate">
          { rate > 0 ? <StarActive onClick={() => setRate(1)} /> : <Star onClick={() => setRate(1)} /> }
          { rate > 1 ? <StarActive onClick={() => setRate(2)} /> : <Star onClick={() => setRate(2)} /> }
          { rate > 2 ? <StarActive onClick={() => setRate(3)} /> : <Star onClick={() => setRate(3)} /> }
          { rate > 3 ? <StarActive onClick={() => setRate(4)} /> : <Star onClick={() => setRate(4)} /> }
          { rate > 4 ? <StarActive onClick={() => setRate(5)} /> : <Star onClick={() => setRate(5)} /> }
        </div>
      </div>

      <div className="quiz-top-text-container align-left quiz-text-small color-light-gray">{i18next.t('RateQuiz.comment')}</div>

      <List noHairlinesMd style={{ marginTop: 0 }}>
        <ul>
          <ListInput
            ref={inputRef}
            outline
            label={t<string>('RateQuiz.commentPlaceholder')}
            floatingLabel
            type="text"
            placeholder={t<string>('RateQuiz.commentPlaceholder')}
            info=" "

            errorMessage={error}
            errorMessageForce={Boolean(error)}
          />
        </ul>
      </List>

      <div className="quiz-container" style={{ marginTop: 4 }}>
        <Button
          disabled={!rate}
          onClick={handleSubmit}
        >
          {i18next.t('common.send')}
        </Button>
      </div>

    </div>
  )
}


const lateDialogInit = () => {
  const dialogInstance = f7.dialog.create({
    cssClass: 'quiz-settings-promo-code-dialog',
    closeByBackdropClick: true,
    title: '',
  })

  const close = () => dialogInstance.close()

  render(
    <div>
      <CloseIcon className="quiz-settings-promo-code-dialog-close" onClick={close} />
      <RateDialogBody close={close} dialogInstance={dialogInstance} />
    </div>
    ,dialogInstance.el.children[0]
  )

  return dialogInstance
}

export default lateDialogInit
