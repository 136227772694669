import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'framework7-react/components/page';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';

import { Button, Cooldown, Loader, Option } from 'components';
import { ReactComponent as IconCoins } from 'assets/coins.svg'
import { ReactComponent as IconInfo } from 'assets/info.svg'
import { ReactComponent as IconMute } from 'assets/mute.svg';
import { ReactComponent as IconUnMute } from 'assets/unmute.svg';
import { OfflineQuizStore, Store } from 'store';
import clsx from 'clsx';


import '../OnlineQuiz/OnlineQuiz.less'; // Use same styles
import './OfflineQuiz.less'
import { ColorClasses, ColorTimePoints } from '../../components/Cooldown/Cooldown';
import { logAmplitude } from 'utils/amplitude';
import { setTimeout } from '../../utils/timeout';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const coolDownColorTimePoints: ColorTimePoints = {
  active: 20,
  warning: 10,
  error: -1,
}

const coolDownColorTimePointsReading: ColorTimePoints = {
  active: 4,
  warning: 2,
  error: -1,
}

const colorClasses: ColorClasses = {
  active: 'quiz-cooldown-success',
  warning: 'quiz-cooldown-warning',
  error: 'quiz-cooldown-error',
}

let questionStartTime = 0
let questionShowTime = 0
let questionEndTime = 0
let questionReadingEndTime = 0

const OfflineQuiz: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation();

  const { quiz, question, answerResult } = OfflineQuizStore


  const [ loading, setLoading] = useState(false)
  const [ isAnswer, setIsAnswer ] = useState(false)
  const [ freeze, setFreeze ] = useState(true)
  const [ questionReading, setQuestionReading ] = useState(false)
  const [ timeEnd, setTimeEnd ] = useState(0)
  const [ selectedId, setSelectedId ] = useState<string | null>(null)
  const [ correctId, setCorrectId ] = useState<string | null>(null)
  const [ incorrectId, setIncorrectId ] = useState<string | null>(null)

  const selectedIdRef = useRef<string | null>(null)
  const coolDownText = useRef<string | undefined>(t('OfflineQuiz.timeEnd'))


  // New question effect
  useEffect(() => {
    if (!question) return

    setIsAnswer(false)
    setCorrectId(null)
    setIncorrectId(null)


    questionShowTime = Date.now()
    questionReadingEndTime = questionShowTime + 5000
    questionEndTime = questionShowTime + 30000

    setTimeEnd(questionReadingEndTime)
    setQuestionReading(true)
    setFreeze(false)
    coolDownText.current = undefined
  }, [question?.id])

  const handleQuestionRead = () => {
    if (!questionReading) return

    coolDownText.current = t('OfflineQuiz.timeEnd')
    questionStartTime = Date.now()
    setTimeEnd(questionEndTime)
    setQuestionReading(false)
  }

  // Show question result effect
  useEffect(() => {
    if (!answerResult?.questionId || question?.id !== answerResult?.questionId) return

    setIsAnswer(true)

    if (answerResult.correct) {
      setCorrectId(selectedId)
      Store.playSound('correctAnswer')
      logAmplitude('tvquiz_app_answer_correct', { number: question?.index ?? -1, quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })
    } else {
      setIncorrectId(selectedId)
      Store.playSound('incorrectAnswer')
    }

    setSelectedId(null)
    selectedIdRef.current = null

  }, [answerResult?.questionId])

  const handleOptionClick = () => {
    if (questionReading || loading || !quiz || !question) return

    if (OfflineQuizStore.quizResult) {
      logAmplitude('tvquiz_app_result', { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })
      $f7router.navigate('/offline-quiz-finish' , { pushState: false, history: false })
      return
    }

    setLoading(true)

    if (isAnswer) {
      logAmplitude('tvquiz_app_quiz_next_clicked', { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })

      OfflineQuizStore.getNextQuestion({
        quizId: quiz.id,
      })
        .catch(() => {
          $f7router.navigate('/play')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setFreeze(true)

      logAmplitude('tvquiz_app_quiz_answer_clicked' , { quizId: OfflineQuizStore.quiz?.id ?? '__EMPTY__', quizName: OfflineQuizStore.quiz?.name ?? '__EMPTY__' })

      OfflineQuizStore.makeAnswer({
        quizId: quiz.id,
        answerId: selectedIdRef.current ?? selectedId ?? undefined,
        questionId: question.id,
        duration: Date.now() - questionStartTime,
        factId: question.factId,
      })
        .catch(() => {
          $f7router.navigate('/play')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleOptionSelect = (event: React.MouseEvent<HTMLElement>) => {
    const optionId = event.currentTarget.dataset.id

    if (!optionId || isAnswer || questionReading || loading) return

    setSelectedId(optionId)
    selectedIdRef.current = optionId
    coolDownText.current = undefined
  }

  if (!quiz || !question) {
    return (
      <Page>
        <Loader className="quiz-loader" />
      </Page>
    )
  }

  return (
    <Page>
      <div className="quiz-top-text-container">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-caption quiz-font-400">{t('Question.index', { index: question.index, total: quiz.totalQuestions })}</span>

        <div
          className={clsx('quiz-card quiz-question-card', questionReading ? 'quiz-question-card-reading' : null)}
          onClick={handleQuestionRead}
        >

          <Cooldown
            freeze={freeze}
            time={questionReading ? questionReadingEndTime : questionEndTime }
            className="quiz-question-cooldown"
            onEnd={questionReading ? handleQuestionRead : handleOptionClick}
            colorTimePoints={questionReading ? coolDownColorTimePointsReading : coolDownColorTimePoints}
            endText={coolDownText}
            colorClasses={colorClasses}
          />

          <div className="quiz-font-600 quiz-question-question">{question.text}</div>
          <div className="quiz-text-caption quiz-font-600 quiz-question-variants-container">
            {question.options.map(({ text, id }) => (
              <Option
                key={id}
                data-id={id}
                onClick={handleOptionSelect}
                invisible={questionReading}
                variant={id === selectedId
                  ? 'active'
                  : id === correctId
                    ? 'success'
                    : id === incorrectId
                      ? 'error'
                      : undefined
              }
              >
                {text}
              </Option>
            ))}
          </div>
        </div>

        <div className="quiz-offline-question-container">
          {
            isAnswer
              ? (
                <div className="quiz-tip">
                  <IconCoins />
                  <span className="quiz-text-caption quiz-font-500">{t('OfflineQuiz.totalPoints')}:&nbsp;{answerResult?.score}</span>
                </div>
              )
              : null
          }

          <div className="quiz-tip">
            <IconInfo />
            <span className="quiz-text-small quiz-font-500">{t('OfflineQuiz.fast')}</span>
          </div>

          <Button
            disabled={!isAnswer && !selectedId}
            loading={loading}
            onClick={handleOptionClick}
            className="mt-24"
          >
            {
              isAnswer
                ? (OfflineQuizStore.quizResult ? t('OfflineQuiz.result') : t('OfflineQuiz.nextQuestion'))
                : t('OfflineQuiz.makeAnswer')
            }
          </Button>

          <div className="quiz-offline-mute mt-24 quiz-font-500 quiz-text-caption" onClick={() => Store.mute(!Store.muted)}>
            {
              Store.muted
                ? <IconUnMute />
                : <IconMute />
            }
            <span>
              {
                Store.muted
                  ? t('common.unmute')
                  : t('common.mute')
              }
            </span>
          </div>

        </div>
      </div>
    </Page>
  );
}

export default observer(OfflineQuiz)

