import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import { Answer, AnswerResult, AnswerState } from 'types';
import { Button, Option } from 'components';
import { logAmplitude } from 'utils/amplitude';

import './AnswerResult.less';
import Sad from 'assets/sad.png';

interface IProps {
  result: AnswerResult;
  correctAnswered: number;
  answer?: Answer;
  questionText: string;
  questionNumber: number;
  totalQuestions: number;
  onClose: () => void;
  playSound: (name: string, mute?: boolean) => Promise<void>;
}

const AnswerResultComponent: React.FC<IProps> = ({ questionText, questionNumber, result, totalQuestions, answer, onClose, correctAnswered, playSound }) => {
  const { t } = useTranslation();
  const secondsElementRef = useRef<HTMLSpanElement | null>(null);

  let message = '';

  const isAnswerSelected = Boolean(answer);
  let isNoLuck = false;

  switch (result.state) {
    case AnswerState.BURNED_LIFE: message = t('AnswerResult.burnedLife'); break;
    case AnswerState.LOSE:
      isNoLuck =  questionNumber >= 15;
      message = isNoLuck ? t('NoLuck.description') : t('AnswerResult.lose');
    break;
  }

  useEffect(() => {
    switch (result.state) {
      case AnswerState.BURNED_LIFE: logAmplitude('tvquiz_app_answer_life'); playSound('incorrectAnswer'); break;
      case AnswerState.PASSED: logAmplitude('tvquiz_app_answer_correct'); playSound('correctAnswer'); break;
      case AnswerState.LOSE: logAmplitude('tvquiz_app_answer_incorrect'); playSound('incorrectAnswer'); break;
    }
  }, [ result.questionId ]);

  useEffect(() => {
    if (!result.nextQuestionStartTime || !secondsElementRef.current) return;

    const diffMS = differenceInMilliseconds(result.nextQuestionStartTime, new Date());
    secondsElementRef.current!.innerText = t('common.inSeconds', { count: diffMS / 1000 >> 0});

    let interval = 0;
    const timeout = window.setTimeout(() => {
      interval = window.setInterval(() => {
        const diff = differenceInSeconds(result.nextQuestionStartTime!, new Date());

        if (diff < 1) {
          clearInterval(interval);
          return;
        }

        secondsElementRef.current!.innerText = t('common.inSeconds', { count: diff })

      }, 1_000);
      secondsElementRef.current!.innerText = t('common.inSeconds', { count: differenceInSeconds(result.nextQuestionStartTime!, new Date()) })
    }, diffMS % 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    }
  }, [ secondsElementRef.current, result.nextQuestionStartTime ])

  return (
    <>
      <div className="quiz-top-text-container">
        <span className="quiz-text-caption quiz-font-400">{t('Question.index', { index: questionNumber, total: totalQuestions})}</span>

        <div className="quiz-card quiz-question-card">
          {isNoLuck
            ? <div className="quiz-card-icon">
                <img src={Sad} alt="Sad" />
              </div>
            :  <div className="quiz-font-600 quiz-question-question">{questionText}</div>
          }

          <div className={'quiz-font-600 quiz-text-caption ' + (result.correct ? 'quiz-result-caption-correct' : 'quiz-result-caption-incorrect')}>
            {result.correct ? t('AnswerResult.correct') :  t(isAnswerSelected ? 'AnswerResult.incorrect' : 'AnswerResult.notSelected')}
          </div>

          <div className="quiz-text-caption quiz-font-600 quiz-result-container">
            {result.correct
              ? <Option variant="success">{result.correctAnswer.text}</Option>
              : <>
                {answer && <Option variant="error">{answer.text}</Option>}
                <Option variant="success">{result.correctAnswer.text}</Option>
              </>
            }
          </div>

          { isNoLuck ? <p>{t('NoLuck.title')}</p> : null }
          { message ? <p>{message}</p> : null }
          { correctAnswered ? <p>{t('AnswerResult.pass', { count: correctAnswered })}</p> : null }
          {
            result.state === AnswerState.LOSE
              ? <Button big onClick={onClose}>{t('common.close')}</Button>
              : <p>
                  {t('AnswerResult.nextQuestionMessage')}
                  <br />
                  <span style={{ color: '#67EDFF' }} ref={secondsElementRef} />
                </p>
          }

        </div>
      </div>
    </>
  );
}

export default React.memo(AnswerResultComponent);

