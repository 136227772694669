
const OPTIONS: {
  requestDelay: number
  token: string
  timeOffset: number

  hostname: string
  pathPrefix: string
  userId: string
} = {
  requestDelay: 3100,
  token: '',
  timeOffset: 0,
  hostname: process.env.REACT_APP_QUIZ_HOST ?? '',
  pathPrefix: '',
  userId: '0',
}

export const setToken = (newToken: string) => OPTIONS.token = newToken;
export const setTimeOffset = (newOffset: number) => OPTIONS.timeOffset = newOffset;
export const setUserId = (newUserId: string) => OPTIONS.userId = newUserId;

export default OPTIONS


