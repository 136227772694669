import EventEmitter from 'eventemitter3';

import type { AnswerResult, Question, Quiz } from 'types';

export enum EQuizError {
  api,
  logic,
  network,
  inner
}

export interface QuizError {
  type: EQuizError;
  error?: Error;
  message?: string;
  method?: string;
  data?: any;
}

interface EventTypes {
  'quiz': [Quiz | null];
  'question': [Question];
  'answerResult': [AnswerResult];
  'participants': [number];
  'lifeCount': [number];
  'currentAnswered': [string, number]; // questionId, count
  'winners': [number]; // questionId, count
  'disconnect': [];
  'error': [QuizError]
}

export default abstract class QuizService extends EventEmitter<EventTypes> {
  abstract start(): void; // start quiz service then application ready and auth (getNextQuiz)
  abstract connect(): void; // join lobby
  abstract disconnect(): void; // leave lobby
  abstract makeAnswer(quizId: string, questionId: string, answerId?: string): void;
  abstract fetchQuiz(): void;
};
