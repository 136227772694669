import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Page from 'framework7-react/components/page'
import { Router } from 'framework7/modules/router/router'
import { useTranslation } from 'react-i18next'
import { OfflineQuizStore, OnlineQuizStore } from 'store'
import { CategorySelector, Loader, OfflineQuizCard, OfflineTopBar, Popup, Button } from 'components';
import { OfflineQuiz, OfflineQuizCategory, OfflineQuizStatus } from 'types';
import { logAmplitude } from 'utils/amplitude';

import './OfflineQuizList.less'

interface IProps {
  $f7router: Router.Router
  selectQuiz?: string
}

const playQuiz = (quiz: OfflineQuiz, router: Router.Router) => {
  OfflineQuizStore.getNextQuestion({
    quizId: quiz.id,
  })
    .then(() => {
      OfflineQuizStore.setActiveQuiz(quiz)

      if (OfflineQuizStore.quizResult) {
        router.navigate('/offline-quiz-finish')
      } else {
        router.navigate('/offline-quiz')
      }

    })
    .catch(() => {
      /* noop */
    })

  if (quiz.status === OfflineQuizStatus.NEW) {
    logAmplitude('tvquiz_app_quiz_start_clicked', { quizId: quiz.id, quizName: quiz.name })
  }

  if (quiz.status === OfflineQuizStatus.IN_PROGRESS) {
    logAmplitude('tvquiz_app_quiz_continue_clicked', { quizId: quiz.id, quizName: quiz.name })
  }
}

const OfflineQuizList: React.FC<IProps> = ({ $f7router, selectQuiz }) => {
  const { t } = useTranslation()
  const [ isOfferPopupOpened, setIsOfferPopupOpened ] = useState(false);
  const [ acceptOfferLoading, setAcceptOfferLoading ] = useState(false);
  const [ quiz, setQuiz ] = useState<OfflineQuiz | null>(null)

  const handleQuizAction = (quiz: OfflineQuiz) => {

    if (quiz.offer && !quiz.isUserAcceptOffer) {
      setQuiz(quiz)
      setIsOfferPopupOpened(true)
      return
    }

    playQuiz(quiz, $f7router)
  }

  const handleQuizOfferAcceptClick = async () => {
    if (quiz) {
      setAcceptOfferLoading(true)
      await OfflineQuizStore.acceptOffer(quiz)
      setAcceptOfferLoading(false)
      setIsOfferPopupOpened(false)

      playQuiz(quiz, $f7router)
    }
  }

  const handleQuizOfferRejectClick = () => {
    setIsOfferPopupOpened(false)
  }

  if (OfflineQuizStore.quizzes === null) {
    return (
      <Page>
        <Loader className="quiz-loader" />
      </Page>
    )
  }

  const quizList = OfflineQuizStore.categories[OfflineQuizStore.category]

  let Body

  if (!quizList.length) {
    switch (OfflineQuizStore.category) {
      case OfflineQuizCategory.ALL:
        Body = (
          <div className="quiz-vertical-container">
            <div className="quiz-text-caption quiz-font-800 text-uppercase" style={{ lineHeight: '17px' }}>{t('OfflineList.emptyTitle')}</div>
            <div className="mt-8 quiz-text-caption" style={{ lineHeight: '17px' }}>{t('OfflineList.emptyDescription')}</div>
          </div>
        )
        break;
      case OfflineQuizCategory.NEW:
        Body = (
          <>
            <div className="quiz-text-caption quiz-font-800 quiz-offline-list-title">
              {t("OfflineList.title")}
            </div>
            {!OfflineQuizStore.aitubeQuiz && (
              <CategorySelector
                onCategorySelect={OfflineQuizStore.setCategory}
                selected={OfflineQuizStore.category}
                style={{ marginTop: 30, marginBottom: 6 }}
              />
            )}

            <div className="quiz-vertical-container">
              <div className="quiz-text-caption" style={{ lineHeight: "17px" }}>
                {t("OfflineList.emptyDescription")}
              </div>
            </div>
          </>
        );
        break;
      case OfflineQuizCategory.POPULAR:
      default:
        Body = (
          <>
            <div className="quiz-text-caption quiz-font-800 quiz-offline-list-title">
              {t("OfflineList.title")}
            </div>
            {!OfflineQuizStore.aitubeQuiz && (
              <CategorySelector
                onCategorySelect={OfflineQuizStore.setCategory}
                selected={OfflineQuizStore.category}
                style={{ marginTop: 30, marginBottom: 6 }}
              />
            )}
            <div className="quiz-vertical-container">
              <div
                className="quiz-text-caption quiz-font-800 text-uppercase"
                style={{ lineHeight: "17px" }}
              >
                {t("OfflineList.emptyTitle")}
              </div>
            </div>
          </>
        );
        break;
    }
  } else {
    Body = (
      <>
        <div className="quiz-text-caption quiz-font-800 quiz-offline-list-title">
          {t("OfflineList.title")}
        </div>
        {!OfflineQuizStore.aitubeQuiz && (
          <CategorySelector
            onCategorySelect={OfflineQuizStore.setCategory}
            selected={OfflineQuizStore.category}
            style={{ marginTop: 30, marginBottom: 6 }}
          />
        )}

        <div className="quiz-card-list">
          {quizList.map((quiz) => (
            <OfflineQuizCard
              selected={selectQuiz === quiz.id}
              key={quiz.id}
              className="quiz-offline-list-item"
              quiz={quiz}
              onAction={handleQuizAction}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <Page>
      <div
        className="quiz-container"
        style={{ height: '100vh' }}
      >

        <OfflineTopBar
          style={{ marginTop: 8 }}
          $f7router={$f7router}
          attempts={OfflineQuizStore.attempts}
          points={OfflineQuizStore.totalScore}
        />

        {Body}
      </div>

      <Popup
        opened={isOfferPopupOpened}
        title={t('Main.offerTitle')}
        html={quiz?.offer ?? ''}
      >
        <Button
          outline
          style={{ width: '48%' }}
          className="quiz-font-600"
          onClick={handleQuizOfferRejectClick}
        >
          {t('common.reject')}
        </Button>
        <Button
          loading={acceptOfferLoading}
          style={{ width: '48%' }}
          className="quiz-font-600"
          onClick={handleQuizOfferAcceptClick}>
          {t('common.accept')}
        </Button>
      </Popup>


    </Page>
  )
}

export default observer(OfflineQuizList)



