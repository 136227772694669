import type { Answer, AnswerResult, AnswerState, CorrectAnswered, Lobby, Question, Quiz, Winners } from 'types';

import { makeRequest } from '../request';
import { getDate, getDateWithRequestDelay } from '../utils';

// ======================================================================
// ========================= GET NEXT QUIZ ==============================
// ======================================================================

interface TwirpGetNextQuizRequest {
  // name: string;
}

export interface TwirpGetNextQuizResponse {
  result?: {
    id: string;
    start_time: string | number;
    name: string;
    question_count: number;
    title: string;
    description: string;
    finished: boolean;
    offer: string;
    user_accept_offer: boolean;
  }
}

export const getNextQuiz = (name?: string): Promise<Quiz | void> => {
  return makeRequest<TwirpGetNextQuizRequest, TwirpGetNextQuizResponse>(
    'GetNextQuiz'
  ).then(({ result }) => (result ? {
    id: result.id,
    name: result.name,
    startTime: getDateWithRequestDelay(result.start_time), // new Date(result.start_time),
    questionCount: result.question_count,
    title: result.title,
    description: result.description,
    finished: result.finished,
    offer: result.offer,
    isUserAcceptOffer: result.user_accept_offer,
  } : undefined ))
}

// ======================================================================
// ========================= ENTER LOBBY ================================
// ======================================================================

interface TwirpEnterLobbyRequest {
  quiz_id: string;
}

export const enterLobby = (quizId: string): Promise<void | GetNextQuestionResultError> => {
  return makeRequest<TwirpEnterLobbyRequest, void | TwirpGetNextQuestionError>(
    'EnterLobby',
    { quiz_id: quizId }
  )
}

// ======================================================================
// ========================= LEAVE LOBBY ================================
// ======================================================================

export const leaveLobby = (quizId: string): Promise<void> => {
  return makeRequest<TwirpEnterLobbyRequest, void>(
    'LeaveLobby',
    { quiz_id: quizId }
  );
}

// ======================================================================
// ======================= GET PARTICIPANTS =============================
// ======================================================================

export interface TwirpGetParticipantsResponse {
  count: number;
}

export const getParticipants = (quizId: string): Promise<Lobby> => {
  return makeRequest<TwirpEnterLobbyRequest, TwirpGetParticipantsResponse>(
    'GetParticipants',
    { quiz_id: quizId }
  ).then(result => ({ peopleCount: result.count }));
}

// ======================================================================
// ======================= GET NEXT QUESTION ============================
// ======================================================================

export interface GetNextQuestionRequest {
  quizId: string;
}

interface TwirpGetNextQuestionRequest {
  quiz_id: string;
}

export interface TwirpGetNextQuestionResponse {
  result: {
    question_id: string;
    quiz_id: string;
    question_text: string;
    options: Array<Answer>;
    start_time: string | number;
    end_time: string | number;
    life_count: number;
    question_index: number;
  }
}

export enum GetNextQuestionError {
  EARLY = 'EARLY',
  LATE = 'LATE',
  LOSE = 'LOSE',
}

export interface TwirpGetNextQuestionError {
  error: GetNextQuestionError;
}

export interface GetNextQuestionResult { question: Question, lifeCount: number}
export interface GetNextQuestionResultError { error: GetNextQuestionError }

export const getNextQuestion = (data: GetNextQuestionRequest): Promise<GetNextQuestionResult | GetNextQuestionResultError> => {
  return makeRequest<TwirpGetNextQuestionRequest, TwirpGetNextQuestionResponse | TwirpGetNextQuestionError>(
    'GetNextQuestion',
    {
      quiz_id: data.quizId,
    }
  ).then(({ result, error }: any) => error ? ({ error }) : ({
    lifeCount: result.life_count,
    question: {
      id: result.question_id,
      quizId: result.quiz_id,
      questionText: result.question_text,
      variants: result.options,
      startTime: getDateWithRequestDelay(result.start_time),// new Date(result.start_time),
      endTime: getDate(result.end_time),
      index: result.question_index,
    }
  }))
};

// ======================================================================
// ======================= MAKE ANSWER ==================================
// ======================================================================

export interface MakeAnswerRequest {
  questionId: string;
  quizId: string;
  answerId?: string;
}

interface TwirpMakeAnswerRequest {
  question_id: string;
  quiz_id: string;
  answer_id?: string;
}

export interface TwirpMakeAnswerResponse {
  result: {
    correct: boolean;
    life_count: number;
    next_question_start_time?: string | number,
    correct_answer: Answer;
    state: AnswerState;
    answeredCount: number;
    passThreshold: boolean;
  }
}

export const makeAnswer = (data: MakeAnswerRequest): Promise<AnswerResult> => {
  return makeRequest<TwirpMakeAnswerRequest, TwirpMakeAnswerResponse>(
    'MakeAnswer',
    {
      question_id: data.questionId,
      quiz_id: data.quizId,
      answer_id: data.answerId,
    }
  ).then(({ result}) => ({
    questionId: data.questionId,
    correct: result.correct,
    correctAnswer: result.correct_answer,
    lifeCount: result.life_count,
    // nextQuestionStartTime: result.next_question_start_time ? new Date(result.next_question_start_time) : undefined,
    nextQuestionStartTime: result.next_question_start_time ? getDateWithRequestDelay(result.next_question_start_time) : undefined,
    state: result.state,
    answeredCount: result.answeredCount,
    passThreshold: result.passThreshold,
  }));
};

// ======================================================================
// =================== GET CORRECT ANSWERED =============================
// ======================================================================

export interface GetCorrectAnsweredRequest {
  quizId: string;
  questionId: string;
}

export interface TwirpGetCorrectAnsweredRequest {
  quiz_id: string;
  question_id: string;
}

export const getCorrectAnswered = (data: GetCorrectAnsweredRequest): Promise<CorrectAnswered> => {
  return makeRequest<TwirpGetCorrectAnsweredRequest, CorrectAnswered>(
    'GetCorrectAnswered',
    {
      question_id: data.questionId,
      quiz_id: data.quizId,
    }
  );
};

// ======================================================================
// ======================== GET WINNERS =================================
// ======================================================================

export interface GetWinnersRequest {
  quizId: string;
}

export interface TwirpGetWinnersRequest {
  quiz_id: string;
}

export interface TwirpGetWinnersResponse {
  winners_count: number;
}

export const getWinners = (data: GetWinnersRequest): Promise<Winners> => {
  return makeRequest<TwirpGetWinnersRequest, TwirpGetWinnersResponse>(
    'GetWinners',
    {
      quiz_id: data.quizId,
    }
  ).then(result => ({
    count: result.winners_count
  }));
};
