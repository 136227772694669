import { f7 } from 'framework7-react';
import { render } from 'react-dom';
import React from 'react';
import SettingsPromoCodeDialog from 'screens/SettingsPromoCode/SettingsPromoCodeDialog';

const promoCodeDialogInit = () => {
  const dialogInstance = f7.dialog.create({
    cssClass: 'quiz-settings-promo-code-dialog',
    closeByBackdropClick: true,
    title: '',
  })

  render(
    <SettingsPromoCodeDialog $f7router={f7.view.current.router} onClose={() => dialogInstance.close()}/>,
    dialogInstance.el.children[0]
  )

  return dialogInstance
}

export default promoCodeDialogInit
