import React from 'react'
import { observer } from 'mobx-react-lite'
import Page from 'framework7-react/components/page'
import { Router } from 'framework7/modules/router/router'
import { useTranslation } from 'react-i18next'

import { logAmplitude } from 'utils/amplitude'
import { OfflineQuizStore } from 'store'
import { OfflineQuiz } from 'types'
import { Button, Loader, OfflineQuizCard } from 'components';
import dialogs from 'dialogs';

import './OfflineQuizListHistory.less'
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router
  selectQuiz?: string
}

const OfflineQuizList: React.FC<IProps> = ({ $f7router, selectQuiz }) => {
  const { t } = useTranslation()

  const handleQuizAction = (quiz: OfflineQuiz) => {
    logAmplitude('tvquiz_app_history_retry_clicked', { quizId: quiz.id, quizName: quiz.name })

    if (OfflineQuizStore.attempts < 1) {
      dialogs.promoCodeDialog.open()
      return
    }

    OfflineQuizStore.getNextQuestion({
      quizId: quiz.id,
      useAttempt: true,
    })
      .then(() => {
        OfflineQuizStore.decreaseAttempts()
        OfflineQuizStore.setActiveQuiz(quiz)

        if (OfflineQuizStore.quizResult) {
          $f7router.navigate('/offline-quiz-finish')
        } else {
          $f7router.navigate('/offline-quiz')
        }

      })
      .catch(() => {
        /* noop */
      })
  }

  if (OfflineQuizStore.finishedQuizzes === null) {
    return (
      <Page>
        <Loader className="quiz-loader" />
      </Page>
    )
  }

  return (
    <Page>
      <div className="quiz-top-text-container">
        <div className="quiz-text-caption quiz-font-800 align-left text-uppercase">{
          !aituBridge.isSupported() &&
          <BackButton absolute={false} />
        }{t('OfflineList.historyTitle')}</div>
        <div className="mt-8 quiz-text-caption align-left">{t('OfflineList.passed')}:&nbsp;<strong>{OfflineQuizStore.finishedQuizzesCount}</strong></div>

        {OfflineQuizStore.finishedQuizzes.length === 0
          ? (
            <div className="align-center" style={{ marginTop: 185}}>
                <div className="quiz-text-caption quiz-font-800 text-uppercase" style={{ lineHeight: '17px' }}>{t('OfflineList.historyEmptyTitle')}</div>
                <div className="mt-8 quiz-text-caption" style={{ lineHeight: '17px' }}>{t('OfflineList.historyEmptyDescription')}</div>
                <Button
                  className="mt-16" style={{ width: '280px'}}
                  onClick={() => {
                    logAmplitude('tvquiz_app_back_clicked')
                    $f7router.back('/offline')
                  }}
                >
                  {t('OfflineList.historyEmptyBack')}
                </Button>
            </div>
          )
          : null
        }

        {OfflineQuizStore.finishedQuizzes.map(quiz => (
          <OfflineQuizCard
            selected={selectQuiz === quiz.id}
            history
            key={quiz.id}
            className="quiz-offline-list-item"
            quiz={quiz}
            onAction={handleQuizAction}
          />
        ))}

      </div>
    </Page>
  )
}

export default observer(OfflineQuizList)



