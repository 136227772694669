import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import { Loader } from '../';

import './Cooldown.less';

export interface ColorTimePoints {
  active: number,
  warning: number,
  error: number,
}

export interface ColorClasses {
  active: string
  warning: string
  error: string
}

interface IProps extends React.HTMLAttributes<HTMLDivElement>{
  message?: string;
  time: number;
  onEnd?: () => void;
  freeze?: boolean
  colorTimePoints?: ColorTimePoints
  showLoaderOnEnd?: boolean
  endText?: React.MutableRefObject<string | undefined>
  colorClasses?: ColorClasses
}

const switchClass = (
  elem: HTMLDivElement,
  diff: number,
  colorTimePoints: ColorTimePoints = {
  active: 10,
  warning: 5,
  error: 0,
},
  colorClasses: ColorClasses = {
    active: 'quiz-cooldown-active',
    warning: 'quiz-cooldown-warning',
    error: 'quiz-cooldown-error',
  }
) => {
  const isActive = diff > colorTimePoints.active;
  const isWarning = !isActive && diff > colorTimePoints.warning;
  const isError = !isActive && !isWarning && diff > colorTimePoints.error;

  elem.classList.toggle(colorClasses.active, isActive);
  elem.classList.toggle(colorClasses.warning, isWarning);
  elem.classList.toggle(colorClasses.error, isError);
}

const Cooldown: React.FC<IProps> = ({
  message,
  time,
  className,
  freeze,
  onEnd = () => undefined,
  colorTimePoints,
  showLoaderOnEnd,
  endText,
  colorClasses,
                                      ...props }) => {
  const { t } = useTranslation();

  const progressBarElementRef = useRef<HTMLDivElement | null>(null);
  const progressElementRef = useRef<HTMLDivElement | null>(null);
  const secondsElementRef = useRef<HTMLDivElement | null>(null);

  const interval = useRef(0);
  const finishTimeout = useRef(0)
  const totalTime = useRef(0)
  const [ showLoader, setShowLoader ] = useState(false);

  useEffect(() => {
    clearInterval(interval.current);
    clearTimeout(finishTimeout.current);
    interval.current = 0;
    finishTimeout.current = 0;

    const diffMS =  differenceInMilliseconds(time, new Date());
    totalTime.current = diffMS

    if (secondsElementRef.current) {
      secondsElementRef.current.innerText = t('common.seconds', { count: Math.ceil(diffMS / 1000) })
    }
    if (progressElementRef.current) {
      progressElementRef.current.style.transition = 'none';
      progressElementRef.current.style.width = '100%';

      // https://css-tricks.com/restart-css-animation/
      // -> triggering reflow /* The actual magic */
      // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
      // Oops! This won't work in strict mode. Thanks Felis Phasma!
      // element.offsetWidth = element.offsetWidth;
      // Do this instead:
      void progressElementRef.current.offsetWidth

      switchClass( progressBarElementRef.current!, diffMS / 1000, colorTimePoints, colorClasses );
    }

    setShowLoader(false)
  }, [ time ])

  useEffect(() => {
    if (!progressElementRef.current || !progressBarElementRef.current) return;

    const diffMS = differenceInMilliseconds(time, new Date());

    if (freeze) {
      clearInterval(interval.current);
      clearTimeout(finishTimeout.current);
      interval.current = 0;

      progressElementRef.current.style.transition = 'none';
      progressElementRef.current.style.width = diffMS / totalTime.current * 100 + '%';

      return
    }

    if (diffMS < 1000) {
      secondsElementRef.current!.innerText = ''
      setShowLoader(true);
      onEnd();
      return;
    }

    progressElementRef.current.style.transition = 'width ' + diffMS + 'ms linear';
    progressElementRef.current.style.width = '0%';

    finishTimeout.current = window.setTimeout(() => {
      switchClass( progressBarElementRef.current!, 0, colorTimePoints, colorClasses);
      secondsElementRef.current!.innerText = ''
      setShowLoader(true);
      onEnd();
    }, diffMS);


    interval.current = window.setInterval(() => {
      const diff = differenceInSeconds(time, new Date(), { roundingMethod: 'ceil' });

      if (diff < 1) {
        clearInterval(interval.current);
        return;
      }

      switchClass( progressBarElementRef.current!, diff, colorTimePoints, colorClasses);
      secondsElementRef.current && (secondsElementRef.current.innerText = t('common.seconds', { count: diff }))
    }, 1000);

    return () => {
      clearInterval(interval.current);
      clearTimeout(finishTimeout.current);
      interval.current = 0;
    }

  }, [ freeze, time, progressElementRef.current, progressBarElementRef.current ]);

  return (
    <div
      className={'quiz-cooldown ' + className}
      {...props}
    >
      <div className="quiz-cooldown-progress-bar" ref={progressBarElementRef}>
        <div ref={progressElementRef} />
      </div>

      <div className="quiz-font-600 quiz-cooldown-timer" ref={secondsElementRef}>
        {
          showLoader && (
            showLoaderOnEnd ? (
              <Loader style={{ marginTop: 5 }} />
            ) : (
              endText?.current || null
            )
          )
        }
      </div>


    </div>
  );
}

export default React.memo(Cooldown);
