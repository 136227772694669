import React from 'react';
import { f7 } from 'framework7-react';

const createStyles = (styles: any) => styles;

const styles = createStyles({
  backButtonContainer: (absolute: boolean) => ({
    position: absolute ? 'absolute' : 'relative',
    left: absolute ? 10 : 0,
    top: absolute ? 10 : 0,
    cursor: 'pointer',
    float: absolute ? 'none' : 'left',
    marginRight: absolute ? 0 : 10
  }),
});

interface BackButtonProps {
  onClick: () => void;
}

const BackButtonIcon: React.FC<BackButtonProps> = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 18L9 12L15 6"
      stroke="#A9ADB1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface BackButtonContainerProps {
  absolute?: boolean;
}

const BackButton: React.FC<BackButtonContainerProps> = ({ absolute = true }) => {
  const handleBackClick = () => {
    f7.views.main.router.back();
  };

  return (
    <div style={styles.backButtonContainer(absolute)}>
      <BackButtonIcon onClick={handleBackClick} />
    </div>
  );
};

export default BackButton;
