export interface OfflineState {
  onlineQuizPlanning: boolean
  attempts: number
  totalScore: number
}

export enum OfflineQuizStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum OfflineQuizCategory {
  ALL = 'ALL',
  NEW = 'NEW',
  POPULAR = 'POPULAR',
  LANGUAGES = 'LANGUAGES',
  MUSIC = 'MUSIC',
  DIFFERENT = 'DIFFERENT',
  FACTS = 'FACTS',
  SERIES = 'SERIES',
  CARS = 'CARS',
  FASHION = 'FASHION',
  SPORT = 'SPORT',
  TRAVEL = 'TRAVEL',
  FILMS = 'FILMS'
}

export interface OfflineQuiz {
  id: string
  name: string
  title: string
  description: string
  imageUrl: string
  status: OfflineQuizStatus
  lastAnsweredQuestion?: number // Если викторина в процессе. Те начал играть и не закончил. Актуально для не пройденной викторины
  answeredQuestions?: number // На сколько вопросов ответил. Актуально для пройденной викторины
  totalQuestions: number // Общее количество вопросов
  personalScore?: number // Сколько баллов набрал. Актуально для не пройденной викторины???
  topAnsweredQuestions?: number; // Топ по количеству отвеченных вопросов с первого раза
  topAnsweredPlayers?: number; // Количество игроков ответивших на топ кол-во вопросов с первого раза
  actionText?: string
  actionUrl?: string
  new?: boolean
  popular: boolean
  category: OfflineQuizCategory
  offer?: string
  isUserAcceptOffer?: boolean
  userRateQuiz: boolean
  rate: number
}

export interface Leader {
  firstName: string
  lastName: string
  quizScore: number
}

export interface OfflineAnswer {
  id: string
  text: string
}

export interface OfflineQuestion {
  quizId: string
  id: string
  text: string
  index: number
  options: Array<OfflineAnswer>
  factId: string
}

export interface OfflineAnswerResult {
  questionId: string // only on client!
  score: number // Сколько баллов получил за ответ. Зависит от скорости ответа
  correct: boolean // правильно ответил на вопрос
  correctAnswer: OfflineAnswer // правильный ответ
  // finished?: boolean // Был ли это ответ на последний вопрос
}

export interface OfflineQuizResult {
  answeredQuestions: number // На сколько вопросов ответил. Актуально после ответа на последний вопрос
  totalQuestions: number // Общее количество вопросов. Актуально после ответа на последний вопрос
  totalScore: number // Сколько баллов набрал. Актуально после ответа на последний вопрос
  leaders: Array<Leader> // Список пользаков ответивших на 16+ вопросов с первого раза
}
