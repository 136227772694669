import React, { useEffect, useState } from 'react';
import Page from 'framework7-react/components/page';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';
import format from 'date-fns/esm/format';
import { Trans, useTranslation } from 'react-i18next';

import { logAmplitude } from 'utils/amplitude';
import { Button, Manual, Popup } from 'components';
import { OnlineQuizStore, Store } from 'store';
import { ReactComponent as IconSettings } from 'assets/icon-settings.svg';
import { ReactComponent as ClockImage } from 'assets/Clock.svg';

import './OnlineQuizMain.less';

interface IProps {
  $f7router: Router.Router;
}

const OnlineQuizMain: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation();
  const [ isOfferPopupOpened, setIsOfferPopupOpened ] = useState(false);
  const [ acceptOfferLoading, setAcceptOfferLoading ] = useState(false);

  const { quiz } = OnlineQuizStore;
  const handleSettingsClick = () => {
    $f7router.navigate('/settings');
  }

  if (!quiz || quiz.finished) {
    return (
      <Page>
        <div className="quiz-container">
          <div className="quiz-main-top-bar">
            <span />
            <div className="quiz-main-top-bar__settings" onClick={handleSettingsClick}>
              <IconSettings />
            </div>
          </div>

        <span className="quiz-text-caption quiz-font-800 quiz-main-title">
          <Trans i18nKey="Main.title2">
            <br/>
            <br/>
          </Trans>
        </span>
          <div
            className="quiz-card quiz-main-card quiz-main-no-quiz"
            onClick={() => window.open('https://i2.app.link/Rkz4eRhlzjb') }
          >
            <ClockImage />
            <span className="quiz-text-caption quiz-font-800">{t('Main.noQuiz1')}</span>
            <span className="quiz-text-caption quiz-font-800">{t('Main.noQuiz2')}</span>

          </div>
          <Manual />
        </div>
      </Page>
    )
  }

  const handleWantToPlayClick = async () => {
    if (quiz.isUserAcceptOffer) {
      logAmplitude('tvquiz_app_play');
      $f7router.navigate('/online-quiz');
    } else {
      setIsOfferPopupOpened(true)
    }
  }

  const handleTimeClick = async () => {
    if (quiz.isUserAcceptOffer) {
      logAmplitude('tvquiz_app_play');
      $f7router.navigate('/online-quiz');
    } else {
      setIsOfferPopupOpened(true)
    }
  }

  const handleQuizOfferRejectClick = () => {
    setIsOfferPopupOpened(false);
  }

  const handleQuizOfferAcceptClick = async () => {
    setAcceptOfferLoading(true);
    const isError = await OnlineQuizStore.acceptOffer(quiz.id)
    setAcceptOfferLoading(false);
    setIsOfferPopupOpened(false);

    if (!isError) {
      logAmplitude('tvquiz_app_play');
      $f7router.navigate('/online-quiz');
    }
  }

  return (
    <Page>
      <div className="quiz-container">
        <div className="quiz-main-top-bar">
          <span />
          <div className="quiz-main-top-bar__settings" onClick={handleSettingsClick}>
            <IconSettings />
          </div>
        </div>

        <span className="quiz-text-caption quiz-font-800 quiz-main-title">
          <Trans i18nKey="Main.title2">
            <br/>
            <br/>
          </Trans>
        </span>
        <div className="quiz-card quiz-main-card" onClick={handleTimeClick}>
          <div className="quiz-main-card__title" dangerouslySetInnerHTML={{ __html: quiz.title }} />
          <div className="quiz-main-card__description .quiz-text-caption quiz-font-500" dangerouslySetInnerHTML={{ __html: quiz.description }} />
          <div className="quiz-main-card__startTime">
            <span className="quiz-text-caption quiz-font-500">
              {t('Main.quizStart')}:
            </span>
            <span className="quiz-font-700">
              {format(quiz.startTime, 'd.MM.yyyy в HH:mm')}
            </span>
          </div>

          <Button onClick={handleWantToPlayClick} className="quiz-main-card__button">{t('Main.wantToPlay')}</Button>
        </div>
        <Manual />
      </div>

      <Popup
        opened={isOfferPopupOpened}
        title={t('Main.offerTitle')}
        html={quiz.offer}
      >
        <Button
          outline
          style={{ width: '48%' }}
          className="quiz-font-600"
          onClick={handleQuizOfferRejectClick}
        >
          {t('common.reject')}
        </Button>
        <Button
          loading={acceptOfferLoading}
          style={{ width: '48%' }}
          className="quiz-font-600"
          onClick={handleQuizOfferAcceptClick}>
          {t('common.accept')}
        </Button>
      </Popup>
    </Page>
  );
}

export default observer(OnlineQuizMain);

