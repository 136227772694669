import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Answer, Question } from 'types';
import { Cooldown, Option } from 'components';
import { logAmplitude } from 'utils/amplitude';

import './Question.less';

interface IProps {
  question: Question;
  totalQuestions: number;
  onSelectAnswer: (answer: Answer) => void;
  playSound: (name: string, mute: boolean) => Promise<void>;
}

const QuestionComponent: React.FC<IProps> = ({ question, totalQuestions, onSelectAnswer, playSound }) => {
  const { t } = useTranslation();
  const [ selectedId, setSelectedId ] = useState<string | undefined>();
  const [ isEnd, setIsEnd ] = useState<boolean>(false);

  const onSelect = (id: string, text: string) => {
    if (isEnd) {
      return;
    }
    setSelectedId(id);
    onSelectAnswer({ id, text });
    logAmplitude('tvquiz_app_answer_button', { questionNumber: question.index, variant: id });

    playSound('correctAnswer', true);
    playSound('incorrectAnswer', true);
    playSound('win', true);
  }

  useEffect(() => {
    logAmplitude('tvquiz_app_question', { questionNumber: question.index });
    setIsEnd(false);
  }, [ question.index ])

  const onEnd = useCallback(() => setIsEnd(true), []);

  return (
    <div className="quiz-top-text-container">
      <span className="quiz-text-caption quiz-font-400">{t('Question.index', { index: question.index, total: totalQuestions})}</span>

      <div className="quiz-card quiz-question-card">
        <Cooldown time={Number(question.endTime)} className="quiz-question-cooldown" onEnd={onEnd} showLoaderOnEnd />
        <div className="quiz-font-600 quiz-question-question">{question.questionText}</div>
        <div className="quiz-text-caption quiz-font-600 quiz-question-variants-container">
          {question.variants.map(({ text, id }) => (
            <Option key={id} onClick={() => onSelect(id, text)} variant={id === selectedId ? 'active' : undefined}>{text}</Option>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(QuestionComponent);

