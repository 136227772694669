import React from 'react';
import clsx from 'clsx';

import { Loader } from 'components';

import './Button.less';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  big?: boolean;
  outline?: boolean;
  loading?: boolean;
  white?: boolean;
  block?: boolean;
}

const Button: React.FC<IProps> = ({ big, outline, loading, white, className = '', children, block,  ...props }) => {
  return (
    <button
      className={clsx('quiz-button quiz-text-caption', className, {
        'quiz-button-big': big,
        'quiz-button-outline': outline,
        'quiz-button-loading': loading,
        'quiz-button-white': white,
        'quiz-button-block': block,
      })}
      {...props}
    >
      {loading ? <Loader /> : children}
    </button>
  );
}

export default Button;
