export enum ELang {
  RU = 'RU',
  KK = 'KK'
}

export interface MeSignInfo {
  id: string;
  name: string;
  sign: string;
  lastname?: string;
  avatar?: string;
  avatarThumb?: string;
}

export interface PhoneSignInfo {
  phone: string;
  sign: string;
}

export interface UserInfo {
  userId: string;
  phone: string;
  firstName: string;
  lastName: string;
  lang: ELang;
  promoCode: string;
  isNotificationsEnabled: boolean;
  birthDate: string;
}

export interface UserInfoData {
  user: UserInfo;
}

export interface SignupRequest {
  userId: string;
  firstName: string;
  lastName: string;
  phone: string;
  promoCode?: string;
  lang: ELang;
  isNotificationsEnabled: boolean;
  birthDate: string; // format dd.mm.yyyy
}
