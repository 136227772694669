import React from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';

import { Store } from 'store';
import { Button } from 'components';

import StartImage from 'assets/start.jpg';

import './Start.less';

interface IProps {
  $f7router: Router.Router;
}

const Start: React.FC<IProps> = ({ $f7router }) => {
  const onKkClick = async () => {
    await Store.switchLanguage('kk');
    $f7router.navigate('/welcome');
  }

  const onRuClick = async () => {
    await Store.switchLanguage('ru');
    $f7router.navigate('/welcome');
  }

  return (
    <Page>
      <div className="quiz-container-100" style={{ position: 'relative' }}>
        <div className="quiz-start-bg" style={{ backgroundImage: `url(${StartImage})` }} />
        <div className="quiz-container quiz-start-container" >
          <div className="quiz-text-primary quiz-font-700">Қош келдіңіз!</div>
          <div className="quiz-text-primary quiz-font-700">Добро пожаловать!</div>
        </div>
        <div className="quiz-bottom-container">
          <Button onClick={onKkClick}>Қазақ тілі</Button>
          <Button onClick={onRuClick} style={{ marginTop: 16 }}>Русский язык</Button>
        </div>
      </div>
    </Page>
  );
}

export default Start;
