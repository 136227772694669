import aituBridge from '@btsd/aitu-bridge';
import { v4 as uuid } from 'uuid';
import { copyText } from './common';

const getPhoneOrig = aituBridge.getPhone;

aituBridge.getPhone = () => {
  if (aituBridge.isSupported()) {
    return getPhoneOrig();
  } else {
    let phone: string = localStorage.phone || String(Math.random()).slice(2, 13);
    //   do {
    //     phone = prompt('Введите номер телефона (11 цифр): ', phone) ?? phone;
    //   } while (!phone || !/^\d{11}$/.test(phone));
    localStorage.phone = phone;
    return Promise.resolve({
      phone,
      sign: 'sign',
    });
  }
};

const getMeOrig = aituBridge.getMe;

aituBridge.getMe = () => {
  if (aituBridge.isSupported()) {
    return getMeOrig();
  } else {
    return Promise.resolve({
      id: uuid(),
      name: 'azaza',
      lastname: 'ololo',
      sign: 'sign',
    });
  }
};

const storageGetItemOrig = aituBridge.storage.getItem;

aituBridge.storage.getItem = (key: string) => {
  if (aituBridge.isSupported()) {
    return storageGetItemOrig(key);
  } else {
    return Promise.resolve(window.sessionStorage[key]);
  }
};

const storageSetItemOrig = aituBridge.storage.setItem;

aituBridge.storage.setItem = (key: string, value: string) => {
  if (aituBridge.isSupported()) {
    return storageSetItemOrig(key, value);
  } else {
    window.sessionStorage[key] = value;
    return Promise.resolve();
  }
};

const enableNotificationsOrig = aituBridge.enableNotifications;

aituBridge.enableNotifications = () => {
  if (aituBridge.isSupported()) {
    return enableNotificationsOrig();
  }

  return Promise.resolve({});
}

const disableNotificationsOrig = aituBridge.disableNotifications;

aituBridge.disableNotifications = () => {
  if (aituBridge.isSupported()) {
    return disableNotificationsOrig();
  }

  return Promise.resolve({});
}

const shareOrig = aituBridge.share

aituBridge.share = (text: string) => {
  if (aituBridge.isSupported()) {
    return shareOrig(text);
  } else {
    copyText(text)

    alert('Bridge share stub: text copied: ' + text)

    return Promise.resolve('success')
  }
};
