import { action, configure, makeObservable, observable, runInAction } from 'mobx';
import { logAmplitude } from 'utils/amplitude';
import differenceInMilliseconds from "date-fns/esm/differenceInMilliseconds";

import Store from './Store'

import api from 'api';
import {
  AnswerResult,
  Question,
  Quiz,
  AnswerState,
} from 'types';

import { delay, median } from 'utils/common';
import { setTimeout } from 'utils/timeout';

import QuizService, { EQuizError } from 'services/quiz/quizService';
import QuizServiceHttpPolling from 'services/quiz/quizServiceHttpPolling';
import { preloadSequentially } from '../utils/preload';
import TrophyImageSrc from '../assets/trophy.jpg';

class OnlineQuizStore {
  constructor() {
    makeObservable(this);
    if (process.env.REACT_APP_DEV) {
      (window as any)['$OnlineQuizStore'] = this;
    }
  }

  timeOffset = 0
  init = false

  async syncTime() {
    const offsets = [];
    for (let i = 0; i < 5; i++) {
      const offset = await this.doSyncTime();
      if (offset) {
        offsets.push(offset);
      }

      await delay(400);
    }

    const offset = median(offsets);

    console.log('Median offset:', offset);
    this.timeOffset = offset;
    api.OPTIONS.setTimeOffset(offset);
  }

  async doSyncTime(): Promise<number> {
    try {
      const now = Date.now();
      const serverTimeRaw = await api.getCurrentTime();

      const resNow = Date.now();
      const serverTime = new Date(serverTimeRaw);

      const ping = resNow - now;
      const offset = ( now + (ping / 2) ) - Number(serverTime);

      console.log({ ping, offset });

      api.OPTIONS.setTimeOffset(offset);
      return offset;

    } catch (err) {
      Store.logError(err, { type: 'api', method: 'GetCurrentTime'}, { silent: true });
      console.log(err);
      return 0;
    }
  }

  quizService!: QuizService;
  getParticipantsInterval = 0;

  @observable life = 0;
  @observable peopleInLobby = 0;
  @observable quizError = '';

  @observable quiz: Quiz | null = null;
  @observable question: Question | null = null;
  @observable answerResult: AnswerResult | null = null;
  @observable answerId?: string;
  @observable correctAnswered: number = 0;

  async initQuizService() {
    if (this.init) {
      return
    }

    this.init = true

    // Preload images:
    preloadSequentially(TrophyImageSrc);

    await this.syncTime();
    this.quizService = new QuizServiceHttpPolling();

    this.quizService.on('quiz', quiz => {
      if (this.quiz?.id !== quiz?.id) {
        runInAction(() => {
          this.quiz = quiz
        })
      }
    });
    this.quizService.on('question', (question) => {

      setTimeout(() => {
        this.correctAnswered = 0;
        const answerId = this.answerId;
        this.answerId = undefined;
        this.quizService.makeAnswer(this.quiz!.id, question.id, answerId);
      }, differenceInMilliseconds(question.endTime, new Date()))

      runInAction(() => {
        this.quizError = '';
        this.question = question
      })
    });
    this.quizService.on('answerResult', answerResult => runInAction(() => {
      this.answerResult = answerResult

      if (answerResult.state === AnswerState.WIN
        || answerResult.state === AnswerState.LOSE
      ) {
        this.quiz!.finished = true;
      }
    }));
    this.quizService.on('participants', peopleInLobby => runInAction(() => { this.peopleInLobby = peopleInLobby }));
    this.quizService.on('lifeCount', lifeCount => runInAction(() => { this.life = lifeCount }));
    this.quizService.on('currentAnswered', (questionId, currentAnswered) => runInAction(() => { this.correctAnswered = currentAnswered }));
    this.quizService.on('winners', (winners) => runInAction(() => { this.correctAnswered = winners }));
    this.quizService.on('disconnect', () => {

    });
    this.quizService.on('error', quizError => {
      const { type, method, data, message, error } = quizError;

      const errorData = error ? {
        errorName: error.name,
        errorMessage: error.message,
        rawError: JSON.stringify(error)
      } : {};

      switch (quizError.type) {
        case EQuizError.logic:
          runInAction(() => {
            this.quizError = message ?? 'quiz_error';
          });
          break;

        case EQuizError.api:
          logAmplitude('tvquiz_app_error_api', {
            type,
            method,
            message,
            ...data,
            ...errorData,
          });
          break;
        case EQuizError.network:
          if (message === 'attempt') {
            logAmplitude('tvquiz_app_fetch_retry', {
              method,
              ...data,
            });
            break;
          }

          Store.logError(error, { type, method }, { eventName: 'tvquiz_app_error_network', callback: () => window.location.reload() })
          break;
      }
    })

    this.quizService.start()
  }

  async enterLobby() {
    if (!this.quiz) return;
    this.quizService.connect();
  }

  async leaveLobby() {
    if (!this.quiz) return;
    this.quizService.disconnect();
  }

  async acceptOffer(quizId: string): Promise<void | true> {
    try {
      await api.acceptOffer(quizId);
      this.quiz!.isUserAcceptOffer = true;
    } catch (error) {
      Store.logError(error, { type: 'api', method: 'AcceptOffer' });
      return true;
    }
  }

  @action.bound selectAnswerId(answerId?: string) {
    this.answerId = answerId;
  }

  @action.bound clearCorrectAnswered() {
    this.correctAnswered = 0;
  }

  @action.bound async reset() {
    this.question = null;
    this.answerResult = null;
    this.quizError = '';
    this.correctAnswered = 0;

    if (this.quiz?.finished) {
      this.quiz = null
    }
  }
}

export default new OnlineQuizStore();
