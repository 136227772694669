import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import InputMask from "react-input-mask";
import Page from 'framework7-react/components/page';
import ListInput from 'framework7-react/components/list-input';
import List from 'framework7-react/components/list';
import Popup from 'framework7-react/components/popup';
import Toolbar from 'framework7-react/components/toolbar';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';
import intervalToDuration from 'date-fns/esm/intervalToDuration';
import parse from 'date-fns/esm/parse';

import { ELang, SignupRequest } from 'types';
import { Button } from 'components';
import { Store } from 'store';
import CONSTANTS from 'consts';

interface IProps {
  $f7router: Router.Router;
  profile?: boolean;
}

const Registration: React.FC<IProps> = ({ $f7router, profile }) => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, formState: { errors }, control, getValues, setError, setValue } = useForm<SignupRequest>({
    defaultValues: {
      phone: Store.me!.phone,
      userId: Store.aituMe!.userId,
      firstName: profile ? Store.me?.firstName : Store.aituMe?.firstName,
      lastName: profile ? Store.me?.lastName : Store.aituMe?.lastName || Store.aituMe?.lastName,
      isNotificationsEnabled: profile ? Store.me?.isNotificationsEnabled : true,
      birthDate: profile ? Store.me?.birthDate : '01.01.2000',
      promoCode: (new URLSearchParams(window.location.search)).get(CONSTANTS.promoCodeQueryParam)?.toUpperCase() ?? undefined
    }
  });

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ isKkCitizen, setIsKkCitizen ] = useState<boolean>(true);
  const [ isCitizenPopupOpened, setIsCitizenPopupOpened ] = useState<boolean>(false);
  const [ isAgePopupOpened, setIsAgePopupOpened ] = useState<boolean>(false);

  const onSubmit = async (data: SignupRequest) => {
    if (loading) return;

    if (!profile && !isKkCitizen) {
      setIsCitizenPopupOpened(true);
      return;
    }

    try {

      const start = parse(data.birthDate, 'dd.MM.yyyy', new Date())
      const end = new Date()

      const lifeDuration = intervalToDuration({
        start,
        end,
      });

      const durationYears = lifeDuration?.years ?? 0

      if (start > end || durationYears < 5 || durationYears > 100 ) {
        $f7router.app.dialog.alert(
          t('Registration.ageValueInvalid'),
          t('common.errorDialogTitle'),
        )

        return
      }

      if (durationYears < 16) {
        setIsAgePopupOpened(true)
        return;
      }

    } catch (err) {
      setError('birthDate', { type: 'manual', message: t('Registration.ageInvalid') })
      return;
    }

    await register(data);
  }

  const register = async (data: SignupRequest = getValues()) => {
    data.lang = i18n.language === 'ru' ? ELang.RU : ELang.KK;

    if (profile) { // edit
      setLoading(true);
      await Store.editProfile(data);
      setLoading(false);
      $f7router.back('/settings');
    }
  }

  const handleCitizenPopupClose = () => { setIsCitizenPopupOpened(false) }
  const handleAgePopupClose = () => {
    setIsAgePopupOpened(false);
    register();
  }

  const formattedPhone = useMemo(() => Store.me!.phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5'), [ Store.me!.phone ])

  return (
    <Page>
      <div className="quiz-top-text-container">
        <span className="quiz-text-primary quiz-font-700">{t(profile ? 'Profile.title': 'Registration.title')}</span>
        <span className="quiz-text-caption quiz-font-500" style={{ marginTop: 12 }}>
          <Trans
            i18nKey="Registration.subTitle"
            components={{ br: <br /> }}
          />
        </span>
      </div>
      <List noHairlinesMd className="quiz-font-medium">
        <ul>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: t<string>('common.required')  }}
            render={({ field }) =>
              <ListInput
                outline
                label={t<string>('Registration.nameInputLabel')}
                floatingLabel
                type="text"
                placeholder={t<string>('Registration.nameInputPlaceholder')}
                info=" "
                errorMessage={errors.firstName?.message}
                errorMessageForce={Boolean(errors.firstName)}
                disabled={!profile}
                {...field}
              />
            }
          />

          {!profile ? null : <Controller
            name="lastName"
            control={control}
            rules={{ required: t<string>('common.required')  }}
            render={({ field }) =>
              <ListInput
                outline
                label={t<string>('Registration.lastNameInputLabel')}
                floatingLabel
                type="text"
                placeholder={t<string>('Registration.lastNameInputPlaceholder')}
                info=" "
                errorMessage={errors.lastName?.message}
                errorMessageForce={Boolean(errors.lastName)}
                {...field}
              />
            }
          />}

          <ListInput
            outline
            label={t<string>('Registration.phoneInputLabel')}
            floatingLabel
            type="text"
            placeholder="+7 777 777 77 77"
            info=" "
            disabled
            value={formattedPhone}
          />

          {!profile ? null : <Controller
            name="birthDate"
            control={control}
            rules={{ required: t<string>('common.required'), pattern: { value: /^\d\d\.\d\d.\d\d\d\d$/i, message: t<string>('Registration.birthDatePatternError') }   }}
            render={({ field }) =>
              <InputMask
                mask="99.99.9999"
                {...field}
              >
                {() => <ListInput
                  outline
                  label={t<string>('Registration.birthDate')}
                  floatingLabel
                  type="text"
                  placeholder="10.06.2005"
                  info=" "
                  errorMessage={errors.birthDate?.message}
                  errorMessageForce={Boolean(errors.birthDate)}
                />}
              </InputMask>
            }
          />}

          {profile ? null : <Controller
            name="promoCode"
            control={control}
            render={({ field }) =>
              <ListInput
                outline
                label={t<string>('PromoCode.label')}
                floatingLabel
                type="text"
                info=" "
                {...field}
              />
            }
          />}

        </ul>
      </List>

      <div className="quiz-container" style={{ marginTop: 12 }}>
        {/*{!profile && <label style={{ display: 'flex' }}>*/}
        {/*  <Checkbox value={isKkCitizen} onChange={e => setIsKkCitizen(e.target.checked)}/>*/}
        {/*  <span style={{ marginLeft: 16 }} className="quiz-font-400">{t('Registration.citizen')}</span>*/}
        {/*</label>}*/}
        <Button
          loading={loading}
          style={{ marginTop: 48 }}
          onClick={handleSubmit(onSubmit)}
        >
          {t(profile ? 'Profile.submit': 'common.continue')}
        </Button>
      </div>

      <Popup
        opened={isCitizenPopupOpened}
        closeByBackdropClick={false}
        closeOnEscape={false}
        swipeToClose={false}
        backdrop={false}
        tabletFullscreen
        animate
      >
        <Page>
          <div className="quiz-vertical-container">
            <div className="quiz-container quiz-text-primary quiz-font-700">{t('Registration.citizenErrorTitle')}</div>
            <div className="quiz-top-text-container">{t('Registration.citizenError')}</div>
          </div>

          <Toolbar position="bottom">
            <div className="quiz-popup-button-container">
              <Button
                className="quiz-font-600"
                onClick={handleCitizenPopupClose}
              >
                {t('common.ok')}
              </Button>
            </div>
          </Toolbar>
        </Page>
      </Popup>

      <Popup
        opened={isAgePopupOpened}
        closeByBackdropClick={false}
        closeOnEscape={false}
        swipeToClose={false}
        backdrop={false}
        tabletFullscreen
        animate
      >
        <Page>
          <div className="quiz-vertical-container">
            <div className="quiz-container quiz-text-primary quiz-font-700">{t('Registration.ageErrorTitle')}</div>
            <div className="quiz-top-text-container">{t('Registration.ageError')}</div>
          </div>

          <Toolbar position="bottom">
            <div className="quiz-popup-button-container">
              <Button
                className="quiz-font-600"
                onClick={handleAgePopupClose}
              >
                {t('common.ok')}
              </Button>
            </div>
          </Toolbar>
        </Page>
      </Popup>
    </Page>
  );
}

export default observer(Registration);

