import {
  OfflineAnswer,
  OfflineAnswerResult,
  OfflineQuestion,
  OfflineQuiz,
  OfflineQuizResult,
  OfflineQuizStatus,
  OfflineState,
  OfflineQuizCategory,
} from 'types'

import { makeRequest } from '../request'
import { parseControlChars } from '../../utils/common';

interface TwirpOfflineQuiz {
  id: string
  name: string
  title: string
  description: string
  image_url: string
  status: OfflineQuizStatus
  last_answered_question?: number // Если викторина в процессе. Те начал играть и не закончил. Актуально для не пройденной викторины
  answered_questions?: number // На сколько вопросов ответил. Актуально для пройденной викторины
  total_questions: number // Общее количество вопросов
  personal_score?: number // Сколько баллов набрал. Актуально для не пройденной викторины???
  top_answered_questions?: number; // Топ по количеству отвеченных вопросов с первого раза
  top_answered_players?: number; // Количество игроков ответивших на топ кол-во вопросов с первого раза
  action_text?: string
  action_url?: string
  new?: boolean
  popular: boolean
  category: OfflineQuizCategory
  offer?: string
  user_accept_offer?: boolean
  user_rate_quiz: boolean
  rate: number
}

const parseOfflineQuiz = (data: TwirpOfflineQuiz): OfflineQuiz => ({
  id: data.id,
  name: data.name,
  title: data.title,
  description: data.description,
  imageUrl: data.image_url,
  status: data.status,
  lastAnsweredQuestion: data.last_answered_question ? Number(data.last_answered_question) : undefined,
  answeredQuestions: data.answered_questions ? Number(data.answered_questions) : undefined,
  totalQuestions: Number(data.total_questions),
  personalScore: data.personal_score ? Number(data.personal_score) : undefined,
  topAnsweredQuestions: data.top_answered_questions ? Number(data.top_answered_questions) : undefined,
  topAnsweredPlayers: data.top_answered_players ? Number(data.top_answered_players) : undefined,
  actionText: data.action_text,
  actionUrl: data.action_url,
  new: data.new,
  popular: Boolean(data.popular),
  category: data.category,
  offer: data.offer ? parseControlChars(data.offer, { newLinePadding: true }) : data.offer,
  isUserAcceptOffer: data.user_accept_offer,
  userRateQuiz: data.user_rate_quiz,
  rate: data.rate ?? 0,
})

// ======================================================================
// ================ GET CURRENT OFFLINE QUIZZES =========================
// ======================================================================

export interface TwirpGetOfflineQuizzesResponse {
  online_quiz_planning: boolean
  user_attempts: number
  user_total_score: number
  quizzes: TwirpOfflineQuiz[]
}

export const getQuizzes = (body: any): Promise<{ state: OfflineState, quizzes: Array<OfflineQuiz>}> => {
  return makeRequest<{}, TwirpGetOfflineQuizzesResponse>('GetCurrentOfflineQuizzes', body)
    .then(data => ({
      state: {
        onlineQuizPlanning: data.online_quiz_planning,
        attempts: Number(data.user_attempts),
        totalScore: Number(data.user_total_score),
      },
      quizzes: (data.quizzes || []).map(parseOfflineQuiz),
    }))
}

// ======================================================================
// ================ GET FINISHED OFFLINE QUIZZES ========================
// ======================================================================

export interface TwirpGetOfflineFinishedQuizzesResponse {
  finished_count: number
  quizzes: TwirpOfflineQuiz[]
}

export const getFinishedQuizzes = (): Promise<{ count: number, quizzes: Array<OfflineQuiz>}> => {
  return makeRequest<{}, TwirpGetOfflineFinishedQuizzesResponse>('GetFinishedOfflineQuizzes', {})
    .then(data => ({
      count: Number(data.finished_count),
      quizzes: (data.quizzes || []).map(parseOfflineQuiz),
    }))
}

// ======================================================================
// =================== GET NEXT OFFLINE QUESTION ========================
// ======================================================================

interface TwirpOfflineQuestion {
  quiz_id: string
  question_id: string
  question_text: string
  options: OfflineAnswer[]
  question_index: number
  fact_id: string
}

export enum OfflineQuizError {
  REPLAY_QUIZ_NO_ATTEMPTS = 'REPLAY_QUIZ_NO_ATTEMPTS', // Квиз уже пройден, чтобы перепройти не хватает попыток
  REPLAY_QUIZ_USE_ATTEMPT = 'REPLAY_QUIZ_USE_ATTEMPT', // Квиз уже пройден, нужно подтверждение на списание попытки и начале перепрохождения
  QUIZ_NOT_FOUND = 'QUIZ_NOT_FOUND', // нет такого квиза
  INVALID_MAKE_ANSWER_DATA = 'INVALID_MAKE_ANSWER_DATA',
  INVALID_MAKE_ANSWER_FACT_ID = 'INVALID_MAKE_ANSWER_FACT_ID',
  QUESTION_NOT_FOUND = 'QUESTION_NOT_FOUND',
  ANSWERS_NOT_FOUND = 'ANSWERS_NOT_FOUND',
  SEND_QUESTION_NOT_FOUND = 'SEND_QUESTION_NOT_FOUND', // нет отправленного вопроса хотя викторина в процессе
  QUESTION_INVALID_ORDER = 'QUESTION_INVALID_ORDER', // номер последнего отвеченного вопроса больше номера последнего отправленного
}

interface TwirpGetNextOfflineQuestionRequest {
  quiz_id: string
  replay_quiz_using_attempt?: boolean // хочет ли пользак использовать попытку чтобы перепройти квиз
}

export interface TwirpGetNextOfflineQuestionResponse {
  result: TwirpOfflineQuestion
}

interface TwirpGetNextOfflineQuestionError {
  error: OfflineQuizError
}

interface TwirpGetNextOfflineQuestionAnswerResult {
  answerResult: Required<Pick<TwirpOfflineAnswerResult, 'answered_questions' | 'total_questions' | 'personal_score' | 'leaders'>>
}

export type GetNextOfflineQuestionResult = { question: OfflineQuestion } | { quizResult: OfflineQuizResult }

export const getNextQuestion = ({ quizId, useAttempt } : { quizId: string, useAttempt?: boolean }): Promise<TwirpGetNextOfflineQuestionError | GetNextOfflineQuestionResult> => {
  return makeRequest<TwirpGetNextOfflineQuestionRequest, TwirpGetNextOfflineQuestionResponse | TwirpGetNextOfflineQuestionAnswerResult | TwirpGetNextOfflineQuestionError>(
    'GetNextOfflineQuestion',
    {
      quiz_id: quizId,
      replay_quiz_using_attempt: useAttempt,
    }
  ).then(data => {

    if ('error' in data) {
      return data
    }

    if ('answerResult' in data) {
      return {
        quizResult: {
          answeredQuestions: Number(data.answerResult.answered_questions),
          totalQuestions: Number(data.answerResult.total_questions),
          totalScore: Number(data.answerResult.personal_score),
          leaders: data.answerResult.leaders.map(
            ({
               first_name,
               last_name,
               quiz_score
             }) => ({
              firstName: first_name,
              lastName: last_name,
              quizScore: Number(quiz_score)
            })
          )
        }
      }
    }

    return {
      question: {
        quizId: data.result.quiz_id,
        id: data.result.question_id,
        text: data.result.question_text,
        index: Number(data.result.question_index),
        options: data.result.options,
        factId: data.result.fact_id,
      }
    }
  })
}

// ======================================================================
// ======================= MAKE OFFLINE ANSWER ==========================
// ======================================================================

interface TwirpOfflineAnswerResult {
  quiz_finished: boolean; // Был ли это ответ на последний вопрос
  correct: boolean; // правильно ответил на вопрос
  answer_score: number; // Сколько баллов получил за ответ. Зависит от скорости ответа
  correct_answer: OfflineAnswer; // правильный ответ
  answered_questions?: number; // На сколько вопросов ответил. Актуально после ответа на последний вопрос
  total_questions?: number; // Общее количество вопросов. Актуально после ответа на последний вопрос
  personal_score?: number; // Сколько баллов набрал. Актуально после ответа на последний вопрос
  leaders?: Array<{
    first_name: string
    last_name: string
    quiz_score: number
  }>; // Список пользаков ответивших на 16+ вопросов с первого раза
}

interface TwirpMakeOfflineAnswerRequest {
  quiz_id: string
  question_id: string
  answer_id?: string
  duration: number // ms
  fact_id: string
}

export interface TwirpMakeOfflineAnswerResponse {
  result: TwirpOfflineAnswerResult
}

interface TwirpMakeOfflineAnswerError {
  error: OfflineQuizError
}

export interface MakeOfflineAnswerResult {
  answerResult: OfflineAnswerResult,
  quizResult?: OfflineQuizResult,
}

export const makeAnswer = ({ quizId, questionId, answerId, duration, factId } : { quizId: string, questionId: string, answerId?: string, duration: number, factId: string }): Promise<TwirpMakeOfflineAnswerError | MakeOfflineAnswerResult > => {
  return makeRequest<TwirpMakeOfflineAnswerRequest, TwirpMakeOfflineAnswerResponse | TwirpMakeOfflineAnswerError>(
    'MakeOfflineAnswer',
    {
      quiz_id: quizId,
      answer_id: answerId,
      question_id: questionId,
      duration,
      fact_id: factId,
    }
  ).then(data => {

    if ('error' in data) {
      return data
    }

    const result: MakeOfflineAnswerResult = {
      answerResult: {
        questionId,
        correct: data.result.correct,
        score: Number(data.result.answer_score),
        correctAnswer: data.result.correct_answer,
      }
    }

    if (data.result.quiz_finished) {
      result.quizResult = {
        answeredQuestions: Number(data.result.answered_questions),
        totalQuestions: Number(data.result.total_questions),
        totalScore: Number(data.result.personal_score),
        leaders: (data.result.leaders ?? []).map(
          ({
             first_name,
             last_name,
             quiz_score
           }) => ({
            firstName: first_name,
            lastName: last_name,
            quizScore: Number(quiz_score)
          })
        )
      }
    }

    return result
  })
}

// ======================================================================
// ============================= RATE QUIZ ==============================
// ======================================================================


interface TwirpRateOfflineQuizRequest {
  quiz_id: string
  rate: number
  comment?: string
}

export const rateOfflineQuiz = ({ quizId, rate, comment }: { quizId: string, rate: number, comment?: string }): Promise<void> => {
  return makeRequest<TwirpRateOfflineQuizRequest, void>(
    'RateOfflineQuiz',
    {
      quiz_id: quizId,
      rate,
      comment,
    }
  )
}
