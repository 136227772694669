import React from 'react';
import Framework7 from 'framework7/framework7.esm';
import View from 'framework7/components/view/view';
import Dialog from 'framework7/components/dialog/dialog';
import Calendar from 'framework7/components/calendar/calendar';
import Sheet from 'framework7/components/sheet/sheet';
import Swiper from 'framework7/components/swiper/swiper';
import Toast from 'framework7/components/toast/toast';
import CheckBox from 'framework7/components/checkbox/checkbox';
import Popup from 'framework7/components/popup/popup';
import Toolbar from 'framework7/components/toolbar/toolbar';
// @ts-ignore
import Framework7React from 'framework7-react/utils/plugin';
import F7App from 'framework7-react/components/app';
import F7View from 'framework7-react/components/view';

import './App.less';
import routes from './routes';

const f7params = {
  routes,
  name: 'Aitu Quiz',
  id: 'kz.btsdigital.aitu_quiz',
  touch: {
    mdTouchRipple: false,
  },
  theme: 'md',
};

Framework7.use([View, Dialog, Calendar, Sheet, Swiper, Toast, CheckBox, Popup, Toolbar]);
Framework7React.install.call(Framework7, f7params);

const ios = Boolean(typeof window !== 'undefined' && (window as any).webkit && (window as any).webkit.messageHandlers);

export default function App() {
  return (
    <F7App params={f7params}>
      <F7View
        url="/"
        main
        pushState
        // pushStateSeparator=""
        mdSwipeBack={ios}
        pushStateOnLoad={false}
      />
    </F7App>
  );
}
