function setTimeout(...inArgs: Parameters<typeof window.setTimeout>): number {
  const [handler, timeout, ...args ] = inArgs;
  const timePoint = Date.now() + timeout;
  const interval = window.setInterval(() => {
    if (Date.now() >= timePoint) {
      window.clearInterval(interval);
      handler(...args);
    }
  }, 500);
  return interval;
}

function clearTimeout(timeout: number) { return window.clearInterval(timeout) }

export {
  setTimeout,
  clearTimeout,
}
