import React from 'react';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';
import { Trans, useTranslation } from 'react-i18next';
import aituBridge from '@btsd/aitu-bridge';

import CONSTANTS from 'consts'
import { Store } from 'store';
import { logAmplitude } from 'utils/amplitude';
import { copyText } from 'utils/common';

import './SettingsPromoCode.less';
import BackButton from "../../../assets/BackButton";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  $f7router: Router.Router;
  inDialog?: boolean;
}

const SettingsPromoCode: React.FC<IProps> = ({ $f7router, inDialog, ...etc }) => {
  const { t } = useTranslation();

  const handleCopy = (text: string, amplitudeEvent: string) => {
    copyText(text);

    $f7router.app.toast.show({
      text: t('SettingsPromoCode.copied'),
      position: 'top',
      closeTimeout: 2000,
      destroyOnClose: true,
    });

    logAmplitude(amplitudeEvent)
  }

  const handleShare = (text: string, amplitudeEvent: string) => {
    aituBridge.share(text)
    logAmplitude(amplitudeEvent)
  }

  const promoLink = `${process.env.REACT_APP_DEEP_LINK}?${CONSTANTS.promoCodeQueryParam}=${Store.me?.promoCode}`;

  return (
    <>
      <div className="quiz-top-text-container">
        {
          !aituBridge.isSupported() && !inDialog &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">{t( 'SettingsPromoCode.title')}</span>
        <span className="quiz-text-caption quiz-font-500" style={{ marginTop: 12 }}>
          <Trans
            i18nKey="SettingsPromoCode.subTitle"
            components={{ br: <br /> }}
          />
        </span>
      </div>
      <div className="quiz-container">
        <div className="quiz-settings-promo-code-label quiz-text-small quiz-font-400">{t('common.promoCode')}</div>
        <div className="quiz-settings-promo-code-span quiz-text-regular quiz-font-600">{Store.me?.promoCode}</div>
        <div className="quiz-settings-promo-code-copy quiz-text-caption quiz-font-600" onClick={() => handleCopy(Store.me?.promoCode ?? '', 'tvquiz_app_promocode_copied')}>{t('common.copy')}</div>

        <div className="quiz-settings-promo-code-label quiz-text-small quiz-font-400">{t('SettingsPromoCode.directLink')}</div>
        <div className="quiz-settings-promo-code-span quiz-text-regular quiz-font-600">{promoLink}</div>
        {
          aituBridge.isSupported() ? 
          <div className="quiz-settings-promo-code-share quiz-text-caption quiz-font-600" onClick={() => handleShare(promoLink, 'tvquiz_app_promourl_copied')}>{t('common.share')}</div>
          :
          <div className="quiz-settings-promo-code-copy quiz-text-caption quiz-font-600" onClick={() => handleCopy(promoLink ?? '', 'tvquiz_app_promourl_copied')}>{t('common.copy')}</div>
        }
      </div>
    </>
  );
}

export default observer(SettingsPromoCode);
