import React, { useCallback, useEffect, useRef, useState } from 'react';

import { OnlineQuizStore, Store, OfflineQuizStore  } from 'store';
import { Answer, AnswerState } from 'types';
import { Loader } from 'components';

import Lobby from './atoms/Lobby/Lobby';
import Question from './atoms/Question/Question';
import AnswerResult from './atoms/AnswerResult/AnswerResult';
import Win from './atoms/Win/Win';
import './OnlineQuiz.less';

const useOnlineQuiz = (onlineQuizStore: typeof OnlineQuizStore, store: typeof Store, onClose: () => void, onError: (error: string) => void) => {
  const { quiz, question, answerResult, correctAnswered, quizError } = onlineQuizStore;

  const questionInfo = useRef<{ questionText: string, questionNumber: number, answer?: Answer }>({
    questionText: '',
    questionNumber: 0
  });
  const playSound = useCallback((s, m) => store.playSound(s, m), []);

  useEffect(() => {
    if (quizError) {
      onError(quizError)
    }
  }, [ quizError ])

  useEffect(() => {
    if (quiz === null) {
      onClose()
    }
  }, [ quiz ])

  useEffect(() => {
    if (!question) return;

    questionInfo.current.questionText = question.questionText;
    questionInfo.current.questionNumber = question.index;
    delete questionInfo.current.answer;

  }, [question?.id])

  if (!quiz) return null; // ts stub

  const now = new Date();

  if (!question && quiz.startTime > now) {
    return <Lobby quiz={quiz}/>;
  }

  if (question) {
    const answer = answerResult?.questionId === question!.id ? answerResult : null;

    if (answer) {
      if (answer.state === AnswerState.WIN) {
        return <Win passThreshold={answer.passThreshold} winnersCount={correctAnswered} onClose={onClose} playSound={playSound} />
      }

      return (
        <AnswerResult
          result={answer}
          totalQuestions={quiz.questionCount}
          onClose={onClose}
          correctAnswered={correctAnswered}
          playSound={playSound}
          {...questionInfo.current}
        />
      );
    } else {
      return (
        <Question
          question={question}
          totalQuestions={quiz.questionCount}
          onSelectAnswer={answer => {
            questionInfo.current.answer = answer;
            OnlineQuizStore.selectAnswerId(answer.id)
          }}
          playSound={playSound}
        />
      );
    }
  } else if (answerResult) {
    return (
      <AnswerResult
        result={answerResult}
        totalQuestions={quiz.questionCount}
        onClose={onClose}
        correctAnswered={correctAnswered}
        playSound={playSound}
        {...questionInfo.current}
      />
    );
  }

  return (
    <Loader className="quiz-loader" />
  );
};

export default useOnlineQuiz;
