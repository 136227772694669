import React, { useState } from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';
import { useTranslation } from 'react-i18next';

import { Store } from 'store';
import { Button, Option } from 'components';

import './Settings.less';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const SettingsLanguage: React.FC<IProps> = ({ $f7router }) => {
  const { t, i18n } = useTranslation();

  const [ loading, setLoading ] = useState(false);
  const [ lang, setLang ] = useState(i18n.language);

  const handleSubmitClick = async () => {
    if (loading) return;

    if (i18n.language !== lang) {
      setLoading(true);
      await Store.switchLanguage(lang as any);
      await Store.editProfile({
        lang: lang as any
      });
      setLoading(false)
    }

    $f7router.back('/settings');
  }

  return (
    <Page>
      <div className="quiz-top-text-container">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">{t('Settings.language')}</span>
        <Option variant={lang === 'kk' ? 'selected' : undefined} onClick={() => setLang('kk')} style={{ marginTop: 24 }}>Қазақша</Option>
        <Option variant={lang === 'ru' ? 'selected' : undefined} onClick={() => setLang('ru')}>Русский</Option>
        <Button loading={loading} onClick={handleSubmitClick} style={{ marginTop: 24 }}>{t('Settings.submit')}</Button>
      </div>
    </Page>
  );
}

export default SettingsLanguage;
