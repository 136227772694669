import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLSpanElement>{
}

const Loader: React.FC<IProps> = ({ className = '', ...props }) => {
  return (
    <span className={'preloader ' + className} {...props}>
      <span className="preloader-inner">
        <span className="preloader-inner-gap" />
        <span className="preloader-inner-left">
          <span className="preloader-inner-half-circle" />
        </span>
        <span className="preloader-inner-right">
          <span className="preloader-inner-half-circle" />
        </span>
      </span>
    </span>
  );
}

export default Loader;
