import { action, makeObservable, observable, runInAction } from 'mobx';

import api from 'api';
import { OfflineAnswerResult, OfflineQuestion, OfflineQuiz, OfflineQuizCategory, OfflineQuizResult, } from 'types';
import { methodSlip } from 'utils/slip';
import { delay } from 'utils/common';
import { OfflineQuizError } from '../api/protocol/offlineQuiz';
import Framework7 from 'framework7/components/app/app-class';
import Store from './Store';
import i18next from 'i18next';

function fillCategories(quizzes: Array<OfflineQuiz>): Record<OfflineQuizCategory, Array<OfflineQuiz>> {
  const result: ReturnType<typeof fillCategories> = Object.keys(OfflineQuizCategory).reduce((res, c) => {
    res[c] = []
    return res
  }, {} as any)

  quizzes.forEach(quiz => {
    result[OfflineQuizCategory.ALL].push(quiz)

    if (quiz.new) {
      result[OfflineQuizCategory.NEW].push(quiz)
    }
    if (quiz.popular) {
      result[OfflineQuizCategory.POPULAR].push(quiz)
    }

    if (quiz.category && result[quiz.category]) {
      result[quiz.category].push(quiz)
    }
  })

  return result
}


class OfflineQuizStore {
  constructor() {
    makeObservable(this);
    if (process.env.REACT_APP_DEV) {
      (window as any)['$OfflineQuizStore'] = this;
    }
  }

  app: Framework7 | null = null;
  aitubeQuiz: boolean = false;

  @action.bound handleError(quizError: OfflineQuizError) {

    // @ts-ignore
    switch (quizError) {
      case OfflineQuizError.QUIZ_NOT_FOUND:
      case OfflineQuizError.INVALID_MAKE_ANSWER_DATA:
      case OfflineQuizError.INVALID_MAKE_ANSWER_FACT_ID:
      case OfflineQuizError.QUESTION_NOT_FOUND:
      case OfflineQuizError.ANSWERS_NOT_FOUND:

      // ???
      case OfflineQuizError.SEND_QUESTION_NOT_FOUND:
      // ???
      case OfflineQuizError.QUESTION_INVALID_ORDER:

      case OfflineQuizError.REPLAY_QUIZ_NO_ATTEMPTS:
      case OfflineQuizError.REPLAY_QUIZ_USE_ATTEMPT:

    }

    this.app?.views.main.router.navigate('/play')

    this.app?.dialog.alert(
      i18next.t('common.unexpectedErrorText', { support: '@support_contest', token: (window as any).ONE_TIME_TOKEN}),
      i18next.t('common.errorDialogTitle') + (quizError),
    );
  }

  @observable attempts: number = 0
  @observable totalScore: number = 0
  @observable quizzes: Array<OfflineQuiz> | null = null
  @observable finishedQuizzes: Array<OfflineQuiz> | null = null
  @observable finishedQuizzesCount: number = 0
  @observable quiz: OfflineQuiz | null = null
  @observable question: OfflineQuestion | null = null
  @observable answerResult: OfflineAnswerResult | null = null
  @observable quizResult: OfflineQuizResult | null = null
  @observable category: OfflineQuizCategory = OfflineQuizCategory.ALL

  categories: Record<OfflineQuizCategory, Array<OfflineQuiz>> = {} as any;

  skipNextRequest = false // prevent duplicate request while application init (see uses of getQuizzes method)

  @action.bound reset() {
    this.quiz = null
    this.question = null
    this.quizResult = null
    this.answerResult = null
  }

  @action.bound async restart(quiz: OfflineQuiz) {
    this.reset()
    this.decreaseAttempts()
    this.setActiveQuiz(quiz)

    return this.getNextQuestion({
      quizId: quiz.id,
      useAttempt: true,
    })
  }

  @action.bound decreaseAttempts() {
    this.attempts --
  }

  @action.bound setActiveQuiz(quiz: OfflineQuiz) {
    this.quiz = quiz
  }

  // boolean result means offline quiz is planning or not
  @action.bound async getQuizzes(skipNextRequest: boolean = false): Promise<boolean> {
    if (this.skipNextRequest) {
      this.skipNextRequest = false
      return false
    }

    try {
      const payload = []
      if (this.aitubeQuiz) {
        payload.push({
          aitube_quiz: true
        })
      }

      const result = await methodSlip(
        api.offlineQuiz.getQuizzes,
        payload as any,
      )

      if (result.state.onlineQuizPlanning) {
        return true
      }

      runInAction(() => {
        this.categories = fillCategories(result.quizzes)

        this.quizzes = result.quizzes
        this.attempts = result.state.attempts
        this.totalScore = result.state.totalScore

        this.skipNextRequest = skipNextRequest

      })

    } catch (err) {
      Store.logError(err, { type: 'api', method: 'GetCurrentOfflineQuizzes' },{ callback: async () => {
        await delay(1000)
        window.location.reload()
      }})
    }

    return false
  }

  @action.bound async getFinishedQuizzes() {
    try {
      const result = await methodSlip(
        api.offlineQuiz.getFinishedQuizzes,
        [],
      )

      runInAction(() => {
        this.finishedQuizzes = result.quizzes
        this.finishedQuizzesCount = result.count
      })

    } catch (err) {
      Store.logError(err, { type: 'api', method: 'GetFinishedOfflineQuizzes' }, { callback: async () => {
          await delay(1000)
          window.location.reload()
        }})

      return Promise.reject(err)
    }
  }

  @action.bound async getNextQuestion(...args: Parameters<typeof api.offlineQuiz.getNextQuestion>) {
     try {
       const gnqResultOrError = await methodSlip(
         api.offlineQuiz.getNextQuestion,
         [...args]
       )

       if ('error' in gnqResultOrError) {
         this.handleError(gnqResultOrError.error)

         return Promise.reject(gnqResultOrError.error)
       }

       runInAction(() => {

         if ('question' in gnqResultOrError) {
           this.question = gnqResultOrError.question
         } else {
           this.quizResult = gnqResultOrError.quizResult
         }
       })

     } catch (err) {
       Store.logError(err, { type: 'api', method: 'GetNextOfflineQuestion' })

       return Promise.reject(err)
     }
  }

  @action.bound async makeAnswer(...args: Parameters<typeof api.offlineQuiz.makeAnswer>) {
    try {
      const resultOrError = await methodSlip(
        api.offlineQuiz.makeAnswer,
        [...args]
      )

      if ('error' in resultOrError) {
        this.handleError(resultOrError.error)

        return
      }

      runInAction(() => {
        this.answerResult = resultOrError.answerResult

        if (resultOrError.quizResult) {
          this.quizResult = resultOrError.quizResult
        }

      })

    } catch (err) {
      Store.logError(err, { type: 'api', method: 'MakeOfflineAnswer' })

      return Promise.reject(err)
    }
  }

  @action.bound setCategory(category: OfflineQuizCategory) {
    this.category = category
  }

  async acceptOffer(quiz: OfflineQuiz): Promise<void | true> {
    try {
      await api.acceptOffer(quiz.id);
      quiz.isUserAcceptOffer = true
    } catch (error) {
      Store.logError(error, { type: 'api', method: 'AcceptOffer' });
      return true;
    }
  }
}

export default new OfflineQuizStore();
