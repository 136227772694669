import React from 'react';
import clsx from 'clsx';

import './Option.less';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'active' | 'selected' | 'success' | 'error'
  invisible?: boolean
}

const Option: React.FC<IProps> = ({ variant, invisible, children, className = '', ...props }) => {
  let customClassName = variant ? 'quiz-option-' + variant : '';

  return (
    <div
      className={clsx(['quiz-option', customClassName, className, invisible && 'quiz-option-invisible'])}
      {...props}
    >
      {children}
    </div>
  );
}

export default Option;
