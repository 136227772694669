import promoCodeDialogInit from './promoCodeDialog';
import lateDialogInit from './onlineQuizLate';
import rateQuizDialogInit from './rateQuizDialog';

import { Dialog } from 'framework7/components/dialog/dialog';

const dialogsStore: Record<string, Dialog.Dialog> = {}

const dialogs = {
  get promoCodeDialog() {
    return dialogsStore.promoCodeDialog || (dialogsStore.promoCodeDialog = promoCodeDialogInit())
  },
  get lateDialog() {
    return dialogsStore.lateDialog || (dialogsStore.lateDialog = lateDialogInit())
  },
  get rateQuizDialog() {
    return dialogsStore.rateQuizDialog || (dialogsStore.rateQuizDialog = rateQuizDialogInit())
  },
}

export default dialogs
