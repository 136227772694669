import React, { useState } from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';
import { useTranslation } from 'react-i18next';

import { Store } from 'store';
import { Button, Option } from 'components';

import './SettingsFAQ.less';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const FAQ_RU: React.FC = () => (
  <>
    <p className="quiz-faq-question">Какие бывают викторины?</p>
    <p className="quiz-faq-answer">
      Викторины делятся на два формата: в прямом эфире и регулярные. Викторины в прямом эфире начинаются в определенное время, если вы опоздаете к началу, то ваши ответы не будут засчитаны. Регулярные викторины можно проходить в любое удобное для вас время.
    </p>

    <p className="quiz-faq-question">Как участвовать в викторине?</p>
    <p className="quiz-faq-answer">
      Зайдите в мини-приложение "Aitu Викторины" и ознакомьтесь с правилами участия. У каждой викторины в прямом эфире есть определенные условия.
    </p>

    <p className="quiz-faq-question">Сколько в викторине вопросов?</p>
    <p className="quiz-faq-answer">
      В каждой Викторине есть определенное количество вопросов, на каждый из которых есть несколько вариантов ответа. Количество вопросов указывается в начале Викторины.
    </p>

    <p className="quiz-faq-question">Есть ли у меня право на ошибку?</p>
    <p className="quiz-faq-answer">
      В викторинах в прямом эфире у каждого участника есть "жизни", которые спасают при неправильном ответе. Ими можно пользоваться с 1-го по 16 вопросы, дальше 16-го вопроса "жизни" не действуют. Их можно получить за регистрацию друзей по своему промокоду или ссылке. В регулярных викторинах Вы можете ошибаться и продолжать играть, но чем больше ошибок - тем меньше баллов.
    </p>

    <p className="quiz-faq-question">Как пригласить друга?</p>
    <p className="quiz-faq-answer">
      Для того чтобы позвать друга, нужно зайти в приложение викторины и нажать на кнопку "Попытки", в появившемся окне нужно скопировать промокод или ссылку и поделиться им со своим другом. Ваш друг введет данный промокод при регистрации и у вас появится дополнительная жизнь.
    </p>

    <p className="quiz-faq-question">
      Сколько друзей я могу пригласить?
    </p>
    <p className="quiz-faq-answer">
      Вы можете приглашать неограниченное количество друзей и получать за каждое приглашение одну "жизнь".
    </p>

    <p className="quiz-faq-question">Сколько длится один вопрос?</p>
    <p className="quiz-faq-answer">
      В викторинах в прямом эфире ответы на вопросы принимаются в течение времени, указанного на экране. В регулярных викторинах один вопрос длится 25 секунд и дает 5 баллов, каждые 5 секунд у вас отнимается 1 балл, поэтому постарайтесь отвечать на вопросы как можно быстрее.
    </p>

    <p className="quiz-faq-question">Могу ли я менять выбранный ответ?</p>
    <p className="quiz-faq-answer">
      Вы можете менять выбранный ответ до истечения времени. Поданным считается ответ, выбранный на момент истечения времени или после нажатия кнопки "Ответить".
    </p>

    <p className="quiz-faq-question">Могу ли я играть с двух аккаунтов?</p>
    <p className="quiz-faq-answer">
      Нет. Один участник участвует в одной и той же Викторине не более 1-го раза.
    </p>

    <p className="quiz-faq-question">Где я увижу свой результат?</p>
    <p className="quiz-faq-answer">
      После окончания Викторины вы увидите результаты правильных ответов и набранных баллов, также вы можете увидеть список лидеров, где размещены пользователи с наивысшими баллами по этой викторине.
    </p>

    <p className="quiz-faq-question">Что мне делать с набранными баллами?</p>
    <p className="quiz-faq-answer">
      Проходите регулярные викторины и копите баллы. В скором времени будет возможность обменять их на призы.
    </p>

    <p className="quiz-faq-question">Как мне узнать о следующей викторине?</p>
    <p className="quiz-faq-answer">
      Всю информацию мы публикуем в канале "<span className="quiz-link" onClick={() => window.location.href = 'https://i2.app.link/WVWgxZU2txb'}>Aitu&nbsp;Викторина</span>", следите за новостями :)
    </p>
  </>
)

const FAQ_KK: React.FC = () => (
  <>
    <p className="quiz-faq-question">Қандай викториналар өткізіледі?</p>
    <p className="quiz-faq-answer">
      Викториналардың екі форматы бар: тікелей эфирде және жүйелі. Тікелей эфирдегі викториналар белгілі уақытта басталады, оның басталуына кешіксеңіз, онда жауаптарыңыз саналмайды. Жүйелі викториналарды өзіңізге ыңғайлы кез келген уақытта өтуге болады.    </p>

    <p className="quiz-faq-question">Викторинаға қалай қатысуға болады?</p>
    <p className="quiz-faq-answer">
      "Aitu Викториналары" ықшам қолданбасына кіріп, қатысу ережелерімен танысыңыз. Тікелей эфирдегі әр викторинаның белгілі шарттары бар.    </p>

    <p className="quiz-faq-question">Викторинада қанша сұрақ болады?</p>
    <p className="quiz-faq-answer">
      Әр Викторинада белгілі сұрақ саны болады, олардың әр қайсысының бірнеше жауап нұсқасы бар. Сұрақтардың саны Викторинаның басында көрсетіледі.    </p>

    <p className="quiz-faq-question">Қате жасауға бола ма?</p>
    <p className="quiz-faq-answer">
      Тікелей эфирдегі викториналарда әр қатысушының қате жауап бергенде құтқаратын "жандары" болады. Оларды 1-16 аралығындағы сұрақтарда пайдалануға болады, 16-сұрақтан кейін "жандар" жарамсыз. Оларды промокод не сілтеме бойынша достарыңыздың тіркелуі үшін алуға болады. Жүйелі викториналарда қате жасап, ойынды жалғастыра беруге болады, алайда қате саны көп болған сайын балл саны аз болады.    </p>

    <p className="quiz-faq-question">Досымды қалай шақыруға болады?</p>
    <p className="quiz-faq-answer">
      Досыңызды шақыру үшін викторина қолданбасына кіріп, "Мүмкіндіктер" түймешігіне басыңыз да, ашылған терезедегі промокодты не сілтемені көшіріп алып, досыңызбен бөлісіңіз. Досыңыз осы промокодты тіркелу кезінде енгізгенде, Сізде қосымша "жан" пайда болады.    </p>

    <p className="quiz-faq-question">Қанша досымды шақыруға болады?</p>
    <p className="quiz-faq-answer">
      Шексіз достар санын шақырып, әр шақырылған досыңыз үшін бір "жан" алуыңызға болады.    </p>

    <p className="quiz-faq-question">Бір сұрақ қанша уақыт жалғасады?</p>
    <p className="quiz-faq-answer">
      Тікелей эфирдегі викториналарда сұрақтарға жауап экранда көрсетілген уақыт ішінде қабылданады. Жүйелі викториналарда бір сұраққа 25 секунд және 5 балл беріледі, әр 5 секунд сайын сізден 1 балл шегеріледі, сондықтан сұрақтарға барынша тез жауап беруге тырысыңыз.    </p>

    <p className="quiz-faq-question">Таңдалған жауапты өзгерте аламын ба?</p>
    <p className="quiz-faq-answer">
      Уақыт аяқталғанша таңдалған жауапты өзгертуге болады. Уақыт аяқталғанда таңдалған немесе "Жауап беру" түймешігі басылғаннан кейінгі жауап қабылданады.    </p>

    <p className="quiz-faq-question">Екі аккаунттан ойнауға бола ма?</p>
    <p className="quiz-faq-answer">
      Жоқ. Бір қатысушы бір Викторинаға 1 реттен артық қатыса алмайды.
    </p>

    <p className="quiz-faq-question">Өз нәтижемді қайдан көре аламын?</p>
    <p className="quiz-faq-answer">
      Викторина аяқталған соң дұрыс жауап нәтижелері мен жиналған балл санын көресіз, сондай-ақ сол викторина бойынша ең жоғары балл санын жинаған пайдаланушылары бар көшбасшылар тізімін көре аласыз.    </p>

    <p className="quiz-faq-question">Жиналған балдармен не істей аламын?</p>
    <p className="quiz-faq-answer">
      Жүйелі викториналардан өтіп, балл жинаңыз. Жақында оларды жүлделерге ауыстыруға мүмкіндік болады.    </p>

    <p className="quiz-faq-question">Келесі викторина туралы қайдан біле аламын?</p>
    <p className="quiz-faq-answer">
      Барлық ақпаратты "<span className="quiz-link" onClick={() => window.location.href = 'https://i2.app.link/WVWgxZU2txb'}>Aitu&nbsp;Викторина</span>", арнасында жариялаймыз, жаңалықтарды жіберіп алмаңыз :)
    </p>
  </>
)

const languageMap: { [lng: string]: React.FC } = {
  ru: FAQ_RU,
  kk: FAQ_KK,
}


const SettingsLanguage: React.FC<IProps> = ({ $f7router }) => {
  const { i18n } = useTranslation();

  const Body = languageMap[i18n.language];

  return (
    <Page>
      <div className="quiz-top-text-container">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">FAQ</span>
        <Body />
      </div>
    </Page>
  );
}

export default SettingsLanguage;
