import React, { useRef } from 'react';
import Page from 'framework7-react/components/page';
import Swiper from 'framework7-react/components/swiper';
import SwiperSlide from 'framework7-react/components/swiper-slide';
import { observer } from 'mobx-react-lite';
import { Router } from 'framework7/modules/router/router';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components';

import { ReactComponent as IconButtonArrowRight } from 'assets/icon-button-arrow-right.svg';

import { ReactComponent as Welcome1Image } from 'assets/New.svg';
import { ReactComponent as Welcome2Image } from 'assets/CoinsBig.svg';
import { ReactComponent as Welcome3Image } from 'assets/Play.svg';
import { ReactComponent as Welcome4Image } from 'assets/LifePlus.svg';

import './Welcome.less';

interface IProps {
  $f7router: Router.Router;
}

const Welcome: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation();
  const swiperRef = useRef<any>(null);

  const onButtonClick = () => {
    if (!swiperRef.current) return;

    const swiper = swiperRef.current.swiper;

    if (swiper.isEnd) {
      $f7router.navigate('/registration');
      return;
    }

    swiper.slideNext();
  }

  return (
    <Page>
      <Swiper pagination className="quiz-welcome" ref={swiperRef}>

        <SwiperSlide>
          <Welcome1Image />
          <div className="quiz-text-primary">
            <Trans
              i18nKey="Welcome.screen1.title"
              components={{ br: <br /> }}
            />
          </div>
          <Trans
            i18nKey="Welcome.screen1.content"
            components={{ p: <p /> }}
          />
          <div className="quiz-bottom-container">
            <Button onClick={onButtonClick}>
              {t('Welcome.screen1.button')}
              <IconButtonArrowRight />
            </Button>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <Welcome2Image />
          <div className="quiz-text-primary">
            <Trans
              i18nKey="Welcome.screen2.title"
              components={{ br: <br /> }}
            />
          </div>
          <Trans
            i18nKey="Welcome.screen2.content"
            components={{ p: <p /> }}
          />
          <div className="quiz-bottom-container">
            <Button onClick={onButtonClick}>
              {t('Welcome.screen2.button')}
              <IconButtonArrowRight />
            </Button>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <Welcome3Image />
          <div className="quiz-text-primary">
            <Trans
              i18nKey="Welcome.screen3.title"
              components={{ br: <br /> }}
            />
          </div>
          <Trans
            i18nKey="Welcome.screen3.content"
            components={{ p: <p /> }}
          />
          <div className="quiz-bottom-container">
            <Button onClick={onButtonClick}>
              {t('Welcome.screen3.button')}
              <IconButtonArrowRight />
            </Button>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <Welcome4Image />
          <div className="quiz-text-primary">
            <Trans
              i18nKey="Welcome.screen4.title"
              components={{ br: <br /> }}
            />
          </div>
          <Trans
            i18nKey="Welcome.screen4.content"
            components={{ p: <p /> }}
          />
          <div className="quiz-bottom-container">
            <Button onClick={onButtonClick}>
              {t('Welcome.screen4.button')}
              <IconButtonArrowRight />
            </Button>
          </div>
        </SwiperSlide>

      </Swiper>
    </Page>
  );
}

export default Welcome;

