import React from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';

import SettingsPromoCode from './atoms/SettingsPromoCode'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  $f7router: Router.Router;
}

const SettingsPromoCodePage: React.FC<IProps> = ({ $f7router, ...etc }) => {
  return (
    <Page {...etc} >
      <SettingsPromoCode $f7router={$f7router} />
    </Page>
  );
}

export default SettingsPromoCodePage;
