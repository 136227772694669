/* eslint-disable import/first */
performance?.mark?.('Script');

// import 'core-js/features/object/assign'; // For Android 6.0.
// import 'core-js/features/object/entries'; // For Android 6.0.
// import 'core-js/features/url-search-params'; // For Android 6.0.
// import 'core-js/features/string/pad-start'; // For Android 6.0.
import "core-js";
import "regenerator-runtime/runtime";

/* eslint-disable import/first */
if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

import React from 'react';
import { render } from 'react-dom';

import './i18n';
import App from './App';
// import reportWebVitals from './reportWebVitals';
if (process.env.REACT_APP_DEV) {
  require('utils/development-aitubridge');
}

localStorage?.removeItem('f7router-view_main-history');

if (window.location.pathname !== '/') {
  window.history.replaceState(null, '', '/');
}

render(
  <App />,
  document.getElementById('root')
);

// If you want to startTime measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
