import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';
import aituBridge from '@btsd/aitu-bridge';

import { OfflineQuiz, OfflineQuizStatus } from 'types'
import { Button } from 'components'
import CONSTANTS from 'consts'
import { logAmplitude } from 'utils/amplitude';

import './OfflineQuizCard.less'

import { ReactComponent as CheckGreen } from 'assets/icon-check-green.svg';
import { ReactComponent as CheckYellow } from 'assets/icon-check-yellow.svg';
import { ReactComponent as CheckRed } from 'assets/icon-check-red.svg';
import { ReactComponent as Coins } from 'assets/coins.svg';
import { ReactComponent as Star } from './assets/star.svg';
import { ReactComponent as Rate } from './assets/rate.svg';
import { ReactComponent as Share } from './assets/share.svg';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  quiz: OfflineQuiz
  onAction(quiz: OfflineQuiz): void
  history?: boolean
  selected?: boolean
}

const OfflineTopBar: React.FC<IProps> = ({ quiz, history, selected = false, onAction, className, style, ...etc }) => {
  const { t } = useTranslation()

  const elementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!elementRef.current) return

    if (selected) {
      elementRef.current?.scrollIntoView()
    }
  }, [elementRef.current])

  const handleShare = () => {
    const quiz_link = `${process.env.REACT_APP_DEEP_LINK}?${CONSTANTS.quizQueryParam}=${quiz.id}`
    aituBridge.share(quiz_link)
    logAmplitude('tvquiz_app_quiz_share_button', { quiz_id: quiz.id, quiz_link })
  }

  let actionText = t('Offline.play')

  switch (quiz.status) {
    case OfflineQuizStatus.FINISHED:
      actionText = t('Offline.playAgain')
      break
    case OfflineQuizStatus.IN_PROGRESS:
      actionText = t('common.continue')
      break
  }

  let CheckIcon = CheckRed

  if (quiz.answeredQuestions) {
      if (quiz.answeredQuestions > 19) {
        CheckIcon = CheckGreen
      } else if (quiz.answeredQuestions >= 16) {
        CheckIcon = CheckYellow
      } else {
        CheckIcon = CheckRed
      }
    }

  return (
    <div
      ref={elementRef}
      className={clsx('quiz-offline-card quiz-card quiz-vertical-container ', className, selected && 'quiz-offline-card-selected' )}
      {...etc}
    >

      {
        aituBridge.isSupported() &&
        <div className="quiz-offline-card-share" onClick={handleShare}>
          <Share />
        </div>
      }

      <div className="quiz-text-secondary quiz-font-700 quiz-offline-card-title">{quiz.title}</div>

      <div
        className="quiz-offline-card-image quiz-text-caption quiz-font-500"
        style={{ backgroundImage: `url(${quiz.imageUrl})`}}
      >

        <div className="quiz-offline-card-labels-container quiz-text-regular quiz-font-500">
          {quiz.new ? <div className="quiz-offline-card-label-new">
            <Star />
            <span>NEW</span>
          </div> : null}

          {quiz.rate !== 0 ? <div className={quiz.rate >= 3 ? 'quiz-offline-card-label-rating-high' : 'quiz-offline-card-label-rating' }>
            <Rate />
            <span>{quiz.rate.toFixed(1)}</span>
          </div> : null}
        </div>

        <div>{quiz.description}</div>
      </div>

      {
        history
        ? (
            <div className="quiz-offline-card-info-block quiz-offline-card-info-block-history">
              <div><CheckIcon /><span>{quiz.answeredQuestions}/{quiz.totalQuestions}</span></div>
              <div><Coins /><span>{quiz.personalScore}</span></div>
            </div>
          )
        : null
      }


      {!history && quiz.status === OfflineQuizStatus.FINISHED
        ? (
          <div className="quiz-offline-card-info-block">
            <div className="quiz-text-caption quiz-font-500">{t('Offline.record')}:</div>
            <span className="quiz-offline-card-value">{quiz.answeredQuestions}</span>
          </div>
        )
        : null
      }

      {!history && quiz.status === OfflineQuizStatus.IN_PROGRESS
        ? (
          <div className="quiz-offline-card-info-block">
            <span className="quiz-text-caption quiz-font-500">{t('Offline.stoppedAt')}:</span>
            <span className="quiz-offline-card-value color-cyan">{(quiz.lastAnsweredQuestion ?? 0) + 1}/{quiz.totalQuestions}</span>
          </div>
        )
        : null
      }

      {
        !history && quiz.status !== OfflineQuizStatus.IN_PROGRESS
        ? (
          <div className="quiz-offline-card-info-block">
            <span className="quiz-text-caption quiz-font-500">{t('Offline.record')}:</span>
            <span className="quiz-offline-card-value">{quiz.topAnsweredQuestions ?  quiz.topAnsweredQuestions + '/' + quiz.totalQuestions : '-'}</span>
          </div>
        )
        : null
      }

      {
        !history && quiz.status !== OfflineQuizStatus.IN_PROGRESS
        ? (
            <div className="quiz-offline-card-info-block">
              <span className="quiz-text-caption quiz-font-500">{t('Offline.recordHolders')}:</span>
              <span className="quiz-offline-card-value">{quiz.topAnsweredPlayers || '-'}</span>
            </div>
          )
        : null
      }


      <Button onClick={() => onAction(quiz)} style={{ marginTop: 24 }}>{actionText}</Button>
    </div>
  )
}

export default OfflineTopBar



