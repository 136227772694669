import React from 'react';
import Page from 'framework7-react/components/page';

import Popup from 'framework7-react/components/popup';
import Toolbar from 'framework7-react/components/toolbar';

interface IProps extends Popup.Props {
  title: string
  html: string
}

const MyPopup: React.FC<IProps> = ({ html, title, children, ...popupProps }) => {
  return (
      <Popup
        closeByBackdropClick={false}
        closeOnEscape={false}
        swipeToClose={false}
        backdrop={false}
        tabletFullscreen
        {...popupProps}
      >
        <Page>
          <div className="quiz-popup-top-text-container">
            <span className="quiz-text-primary quiz-font-700">{title}</span>
          </div>
          <div
            className="quiz-popup-top-text-container"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {
            children ? (
              <Toolbar position="bottom">
                <div className="quiz-popup-button-container">
                  {children}
                </div>
              </Toolbar>
            ) : null
          }
        </Page>
      </Popup>
  );
}

export default MyPopup;
