import format from 'date-fns/esm/format';
import { v4 as uuid } from 'uuid';
import { logAmplitude } from 'utils/amplitude';
import { delay } from 'utils/common';

import { mock } from './mock';
import OPTIONS, { setToken } from './options';
import { throwTwirpError,  TOKEN_HEADER } from './twirp';
import api from "./index";


const logAmplitudeIfLag = async (state: any, data: any) => {
  await delay(30_000);

  if (state.isFinished) {
    return;
  }

  logAmplitude('tvquiz_app_fetch_lag', data);
}

export type MakeRequest = <Req extends object = object, Res extends object | void = object> (method: string, data: Req | void, query?: string) => Promise<Res>;

export const makeRequest: MakeRequest = (method, data, query = '') => {

  let addCustomQuery = false;
  const now = new Date();
  if (!(['GetCurrentTime']).includes(method)) {
    console.log(`[${format(now, 'HH:mm:ss.SSS')}]: ${method} api call`);
    addCustomQuery = true;
  }

  if (process.env.REACT_APP_MOCK) {
    return mock(method, data);
  }

  const startTime = performance.now();

  const headers: any = {
    "Content-Type": "application/json",
  };

  if (OPTIONS.token) {
    headers[TOKEN_HEADER] = OPTIONS.token;
  }

  const requestId = uuid();

    const time = (new Date()).toISOString();

    if (addCustomQuery) {
      query = `?requestId=${requestId}&userId=${OPTIONS.userId}&time=${time}&timeOffset=${OPTIONS.timeOffset}`
    }

    const state = { isFinished: false };

    logAmplitudeIfLag(state, {
      requestId,
      userId: OPTIONS.userId,
      time,
      method,
    });

    return window.fetch(OPTIONS.hostname + OPTIONS.pathPrefix + method + query, {
      method: 'POST',
      headers,
      body: JSON.stringify(data ?? {}),
      credentials: 'include',
    }).then(async (resp: Response) => {
      state.isFinished = true;

      const duration = performance.now() - startTime >> 0;

      if (duration > 5_000) {
        logAmplitude('tvquiz_app_fetch_long', {
          status: resp.status,
          method,
          time: now.toISOString(),
          duration,
          durationGroup: duration > 20_000 ? 20 : duration > 10_000 ? 10 : 5,
          requestId,
          userId: OPTIONS.userId
        });
      }

      if (resp.status === 401) {
        window.location.href = await api.getPassportAuthUrl(window.location.href)
        return
      }

      if (!resp.ok) {

        let text = ''
        let json = ''

        try {
          text = await resp.text()
        } catch (e) {}

        try {
          json = await resp.json()
        } catch (e) {}

        logAmplitude('tvquiz_app_api_response_fail', {
          method,
          time: (new Date()).toISOString(),
          duration,
          requestId,
          userId: OPTIONS.userId,
          status: resp.status,
          type: resp.type,
          text,
          json,
        });


        return throwTwirpError((json as any) || { code: resp.status!, msg: text || 'Unknown twirp error' });
      }

      const respToken = resp.headers.get(TOKEN_HEADER);

      if (respToken && respToken !== OPTIONS.token) {
        setToken(respToken!);
      }

      const data = await resp.json();
      return data;
    })
      .catch(err => {

        logAmplitude('tvquiz_app_api_response_error', {
          method,
          time: (new Date()).toISOString(),
          duration:  performance.now() - startTime >> 0,
          requestId,
          userId: OPTIONS.userId,
          data: JSON.stringify(data ?? {}),
          rawError: JSON.stringify(err),
          errMessage: err.message
        });

        state.isFinished = true;

        return Promise.reject(err);
      });
}

