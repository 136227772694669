import React, { useState } from 'react';
import Page from 'framework7-react/components/page';
import { Router } from 'framework7/modules/router/router';
import { useTranslation } from 'react-i18next';

import { Button, Popup } from 'components';
import i18next from 'i18next';
import { parseControlChars } from 'utils/common';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const Offers: React.FC<IProps> = ({ $f7router }) => {
  const { t, i18n } = useTranslation();

  const [ popupOpened, setPopupOpened ] = useState(false)
  const [ aituLoading , setAituLoading ] = useState(false)

  const [ html, setHtml ] = useState('');

  const handleOpenAitu = async () => {
    setAituLoading(true)
    const fileName = i18n.language === 'ru' ? 'offline.txt' : 'offline_kk.txt';
    try {
      const resp = await fetch(require('./data/' + fileName ) .default);
      const textHtml = await resp.text();
      setHtml(parseControlChars(textHtml, { newLinePadding: true }));
      setPopupOpened(true)
    } catch (err) {
      $f7router.app.dialog.alert(
        i18next.t('common.unexpectedErrorText', { support: '@support_contest', token: (window as any).ONE_TIME_TOKEN}),
        i18next.t('common.errorDialogTitle'),
      );
    }
    setAituLoading(false);
  }

  return (
    <Page>
      <div className="quiz-top-text-container quiz-settings">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">{t('Settings.rules')}</span>

        <Button outline onClick={handleOpenAitu} loading={aituLoading}>Aitu Викторина</Button>
      </div>

      <Popup
        opened={popupOpened}
        html={html}
        title="Оферта"
      >
        <Button
          className="quiz-font-600"
          onClick={() => setPopupOpened(false)}
        >
          {t('common.ok')}
        </Button>
      </Popup>
    </Page>
  );
}

export default Offers;
