import React, { ChangeEvent } from 'react';
import Page from 'framework7-react/components/page';
import Checkbox from 'framework7-react/components/checkbox';
import { Router } from 'framework7/modules/router/router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Store } from 'store';
import { Button } from 'components';
import { ReactComponent as IconNotification } from 'assets/Notification.svg';
import { ReactComponent as IconMute } from 'assets/mute.svg';
import { ReactComponent as IconUnMute } from 'assets/unmute.svg';

import './Settings.less';
import aituBridge from "@btsd/aitu-bridge";
import BackButton from "../../assets/BackButton";

interface IProps {
  $f7router: Router.Router;
}

const Settings: React.FC<IProps> = ({ $f7router }) => {
  const { t } = useTranslation();

  const handleNotificationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    Store.toggleNotifications(isEnabled);
  }

  const muted = Store.muted


  return (
    <Page>
      <div className="quiz-top-text-container quiz-settings">
        {
          !aituBridge.isSupported() &&
          <BackButton />
        }
        <span className="quiz-text-primary quiz-font-700">{t('Settings.title')}</span>
        {
          aituBridge.isSupported() &&
          <div className="quiz-settings-notifications">
            <IconNotification />

            <div className="quiz-font-600" style={{ marginTop: 8 }}>{t('SettingsNotifications.question')}</div>
            <label className="quiz-settings-notifications-control">
              <Checkbox
                className="quiz-settings-notifications-checkbox"
                checked={Store.me!.isNotificationsEnabled}
                onChange={handleNotificationChange}
              />
              <span className="quiz-font-600">{t('SettingsNotifications.checkbox')}</span>
            </label>
          </div>
        }
        {process.env.REACT_APP_DEV ? <Button outline onClick={() => $f7router.navigate('/dev')}>🧞‍♂️ Dev tools 🧞‍♂️</Button> : null}
        <Button block outline onClick={() => Store.mute(!Store.muted)}>
          {
            muted
              ? <IconUnMute style={{ fill: '#fff' }} />
              : <IconMute style={{ fill: '#fff' }} />
          }
          <span>
          {
            muted
              ? t('common.unmute')
              : t('common.mute')
          }
          </span>
        </Button>
        <Button outline onClick={() => $f7router.navigate('/settings-faq')}>FAQ</Button>
        { Store.promoCodesEnabled && <Button outline onClick={() => $f7router.navigate('/settings-promo-code')}>{t('Settings.promoCode')}</Button> }
        <Button outline onClick={() => $f7router.navigate('/settings-profile')}>{t('Settings.profile')}</Button>
        <Button outline onClick={() => $f7router.navigate('/settings-language')}>{t('Settings.language')}</Button>
        <Button outline onClick={() => $f7router.navigate('/offers')}>{t('Settings.rules')}</Button>
        <Button outline onClick={() => window.location.href = 'https://i2.app.link/open_chat_with?user_id=f63724ce-2d91-11eb-8aaf-469be5e24aa2'}>{t('Settings.support')}</Button>
      </div>
    </Page>
  );
}

export default observer(Settings);

