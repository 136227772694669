import React from 'react';
import { useTranslation } from 'react-i18next';

import './Manual.css';

interface IProps extends React.HTMLAttributes<HTMLDivElement>{
}

const ManualRU: React.FC = () => (
  <>
    <div className="quiz-font-600 quiz-manual-title">Как играть:</div>
    <p>Викторина начинается в определенное время. Не опоздай! Если не успеешь, то не сможешь участвовать в розыгрыше приза!</p>
    <p>В викторине 15 вопросов и несколько вариантов ответа на каждый.</p>
    <p>Чтобы ответить на вопрос, у тебя есть 20 секунд.</p>
    <p>У каждого участника есть жизни, которые спасают при неправильном ответе. Жизни можно получить за регистрацию друзей по своему промокоду.</p>
    <p>Участники, которые правильно ответили на все вопросы, попадают в розыгрыш главных призов.</p>
  </>
);

const ManualKK: React.FC = () => (
  <>
    <div className="quiz-font-600 quiz-manual-title">Қалай ойнау керек:</div>
    <p>Викторина белгілі бір уақытта басталады. Кешікпе! Егер кешіксеңіз, ұтыс ойынына қатыса алмайсыз!</p>
    <p>Викторина 15 сұрақтан тұрады, әрқайсысына бірнеше жауап нұсқасы берілген.</p>
    <p>Сұраққа жауап беруге 20 секунд уақытың бар.</p>
    <p>Әрбір қатысушының дұрыс жауап бермеген жағдайда оны құтқаратын жандары бар. Жандарды промокод бойынша достарыңды тіркегенің үшін алуға болады.</p>
    <p>Барлық сұрақтарға дұрыс жауап берген қатысушылар бас жүлделердің ұтыс ойынына өтеді.</p>
  </>
);

const languageMap: { [lng: string]: React.FC } = {
  ru: ManualRU,
  kk: ManualKK,
}

const Manual: React.FC<IProps> = ({ className = '', ...props }) => {
  const { i18n } = useTranslation();

  const Body = languageMap[i18n.language];

  return (
    <div className={'quiz-manual ' + className} {...props}>
      <Body />
    </div>
  );
}

export default Manual;
