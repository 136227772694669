const { amplitude } = window as any;

const initAmplitude = (userId: string) => {
  console.info(`Initializing Amplitude with userId=${userId}`);
  amplitude.setUserId(userId);
}

const setAmplitudeUserProperties = (properties: { [key: string]: string | boolean }) => {
  console.info(`Setting Amplitude user properties: ${Object.entries(properties).map((entry) => entry.join('=')).join(', ')}`);
  amplitude.setUserProperties(properties);
}

const logAmplitude = (eventName: string, payload?: { [key:  string]: number | string | boolean }) => {
  const message = `Logging Amplitude event with name=${eventName}`;
  if (payload) {
    console.info(message + ' and payload=', payload);
  } else {
    console.info(message);
  }
  amplitude.logEvent(eventName, payload);
}

export { initAmplitude, setAmplitudeUserProperties, logAmplitude };
