import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Trophy from 'assets/trophy.jpg';
import Sad from 'assets/sad.png';
import { Button } from 'components';

import './Win.less';
import { logAmplitude } from 'utils/amplitude';
import Popup from 'framework7-react/components/popup';
import Page from 'framework7-react/components/page';
import Toolbar from 'framework7-react/components/toolbar';

interface IProps {
  winnersCount: number;
  onClose: () => void;
  playSound: (name: string, mute?: boolean) => Promise<void>;
  passThreshold: boolean;
}

const Win: React.FC<IProps> = ({ passThreshold, winnersCount, onClose, playSound }) => {
  const { t, i18n } = useTranslation();
  const [ getPrizePopupOpened, setGetPrizePopupOpened ] = useState(false);

  useEffect(() => {
    if (passThreshold) {
      logAmplitude('tvquiz_app_answer_final');
      playSound('win');
    }
  }, []);

  const handleGetPrizePopupClose = () => {
    setGetPrizePopupOpened(false);
    onClose();
  }

  if (!passThreshold) {
    return (
      <>
        <div className="quiz-top-text-container quiz-win-no-luck">
          <div className="quiz-card quiz-question-card">
            <div className="quiz-card-icon">
              <img src={Sad} alt="Trophy" />
            </div>
            <div className="quiz-font-700 quiz-win-title">{t('NoLuck.title')}</div>
            <p>{t('NoLuck.winDescription1')}</p>
            <p>{t('NoLuck.winDescription2')}</p>
            <Button big onClick={onClose}>{t('common.close')}</Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="quiz-top-text-container">
        <div className="quiz-card quiz-question-card">
          <div className="quiz-win-icon-container">
            <img src={Trophy} alt="Trophy" width={175} />
          </div>
          <div className="quiz-font-700 quiz-win-title">
            {t('Win.title')}
            <br />
            {t('Win.win')}
          </div>
          {winnersCount ? <p>{t('Win.winners', { count: winnersCount })}</p>: null}
          <p>{t('Win.description')}</p>
          <Button big onClick={() => setGetPrizePopupOpened(true)}>{t('HowToGetPrize.title')}</Button>
        </div>
      </div>

      <Popup
        opened={getPrizePopupOpened}
        closeByBackdropClick={false}
        closeOnEscape={false}
        swipeToClose={false}
        backdrop={false}
        tabletFullscreen
        animate
      >
        <Page>
          <div className="quiz-popup-top-text-container">
            <span className="quiz-text-primary quiz-font-700">{t('HowToGetPrize.title')}</span>
          </div>

          <div className="quiz-popup-top-text-container">
            <div className="quiz-popup-top-text-container">
              {i18n.language === 'ru'
                ? (
                  <>
                    <p>Результаты викторины, а также розыгрыша по сериалу «Сержан Братан» будут размещены в канале викторин - Aitu Викторины - <span className="quiz-link" onClick={() => window.location.href = 'https://i2.app.link/fIA5pmL91wb'}>https://i2.app.link/fIA5pmL91wb</span></p>
                  </>

                )
                : (
                  <>
                    <p>Викторинаның және де «Сержан Братан» сериалы бойынша ұтыс ойынның нәтижелері Aitu Викториналары каналында жарияланады: <span className="quiz-link" onClick={() => window.location.href = 'https://i2.app.link/fIA5pmL91wb'}>https://i2.app.link/fIA5pmL91wb</span></p>
                  </>
                )
              }
            </div>
          </div>

          <Toolbar position="bottom">
            <div className="quiz-popup-button-container">
              <Button
                className="quiz-font-600"
                onClick={handleGetPrizePopupClose}
              >
                {t('common.ok')}
              </Button>
            </div>
          </Toolbar>
        </Page>
      </Popup>
    </>
  );
}

export default Win;

